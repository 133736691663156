<style>
:global([data-svelte-search] input) {
  width: 100%;
  padding: 0.5rem;
  margin: 0;
  border: 1px solid #e0e0e0;
  border-radius: 0.25rem;
}
</style>
<script>
  import { onMount } from "svelte";
  import { createPostRequest } from '../src/create_request'
  import { createGetRequest } from '../src/create_request'
  import { tick } from "svelte"
  import { slide } from "svelte/transition"
  import { settings } from "../src/data_storage.js"
  settings.useLocalStorage()

  import Search from "svelte-search";

  export let shop
  export let update_handler
  export let cancel_handler

  let errors = false
  let adding = false
  let searchInput
  let search

  import PagyNav from '../src/pagy_nav.svelte'
  let pagy = null
  let page = 1
  let kichboxes = []
  pagy = {next: true, kichboxes: kichboxes, prev: true, page: page}

  function mountFromData(data, pushState = false) {
    console.log(data)
    console.log("Number of kichboxes:" + data.kichboxes.length)
    console.log("Paging:" + data.pagy.count)

    kichboxes = data.kichboxes //.concat(data.kichboxes) // endless add
    pagy = data.pagy
  }

  function loadSettings() {
  }

  function saveSettings() {
  }

  onMount(async () => {
    // title = kich.title
    // titleInput.focus()
    fetchKichboxes(page)

    loadSettings()
  })

  function handle_cancel() {
    console.log('handle_cancel')

    adding = false
    if (cancel_handler) {
      cancel_handler()
    }
  }

  function fetchKichboxes(page) {
    fetch(createGetRequest('/shops/' + shop.id + '/kichboxes.json?page='+page+'&without_shopboxes=1'))
      .then(response => response.json())
      .then(data => {
        mountFromData(data)
      })
      .catch(err => {
        alert('error ' + err)
      })
  }

  function handle_search() {
    console.log('handle_search ' + search)

    if (search && search.length > 0) {
      console.log('search_kichboxes' + search)
      fetch(createGetRequest('/kichboxes/search.json?search[value]='+search))
        .then(response => response.json())
        .then(data => {
          mountFromData(data)
        })
        .catch(err => {
          alert('error ' + err)
        })
    } else {
      fetchKichboxes(1)
    }
  }

  function handle_page_next(page) {
    console.log('page next')
    fetchKichboxes(page)
  }

  function handle_page_prev(page) {
    console.log('page prev')
    fetchKichboxes(page)
  }

  function choose_kichbox(kichbox) {
    console.log('choose_kichbox')
    console.log(kichbox)
    fetch(createPostRequest('/shops/' + shop.id + '/add_kichbox.json',
                        'POST',
                        JSON.stringify({kichbox: {id: kichbox.id}})))
      .then(response => response.json())
      .then(data => {
        fetchKichboxes(pagy.page)
      })
      .catch(err => {
        alert('error ' + err)
      })

    if (update_handler) {
      update_handler()
    }
  }
</script>
<div class="add-new mt-6" transition:slide>
  {#if adding}


  {:else}
    <div class="flex flex-row justify-between mb-3">
      <div class="font-bold flex items-center pl-1">Add Kichboxes</div>
      <div class="grow justify-center flex items-center">
        <Search class="text-black"
          hideLabel
          placeholder="Type to search..."
          debounce={1200}
          bind:value={search}
          on:type={(e) => {
            console.log("type", e.detail); // input value
            handle_search()
          }}
          on:clear={() => {
            console.log("clear");
            handle_search()
          }}/>
      </div>
      <button class="btn" on:click|preventDefault={handle_cancel}>Cancel</button>
    </div>

    {#if (kichboxes)}
      <div class="menu-with-toolbar">
        <div class="kichbox-menu pr-2">
          <PagyNav page_next_handler={handle_page_next} page_prev_handler={handle_page_prev} pagy={pagy}/>
          <div class="kichbox-menu-entries">
            {#each kichboxes as kichbox, index}
              <div class="kichbox-menu-entry border-b-2 border-gray-100">
                <div class="kichbox-menu-entry-title">{kichbox.title}</div>
                <div class="kichbox-menu-entry-info"><button class="btn" on:click={() => {choose_kichbox(kichbox)}}>Add</button></div>
              </div>
            {/each}
          </div>
          <PagyNav page_next_handler={handle_page_next} page_prev_handler={handle_page_prev} pagy={pagy}/>
        </div>
      </div>
    {/if}
  {/if}
</div>
