<script>
  export let pagy = {}
  export let page_next_handler
  export let page_prev_handler

  function handle_prev(){
    const prevPage = pagy.prev
    if (prevPage) {
      page_prev_handler(pagy.prev)
    }
  }

  function handle_next(){
    const nextPage = pagy.next
    if (nextPage) {
      page_next_handler(pagy.next)
    }
  }
</script>

<style>
</style>

<div class="flex flex-row items-center justify-end gap-3 pt-2">
  <a
    href="#"
    on:click|preventDefault={handle_prev}
    class="inline-flex size-8 items-center justify-center rounded border border-gray-100 dark:border-gray-400 rtl:rotate-180"
  >
    <span class="sr-only">Prev Page</span>
    <svg xmlns="http://www.w3.org/2000/svg" class="size-5" viewBox="0 0 20 20" fill="currentColor">
      <path
        fill-rule="evenodd"
        d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
        clip-rule="evenodd"
      />
    </svg>
  </a>

  <p class="text-xs ">
    {pagy.page}
    <span class="mx-0.25">/</span>
    {pagy.last}
    <span class="mx-0.25">[</span>
    {pagy.count}
    ]
  </p>

  <a
    href="#"
    on:click|preventDefault={handle_next}
    class="inline-flex size-8 items-center justify-center rounded border border-gray-100 dark:border-gray-400 rtl:rotate-180"
  >
    <span class="sr-only">Next Page</span>
    <svg xmlns="http://www.w3.org/2000/svg" class="size-5" viewBox="0 0 20 20" fill="currentColor">
      <path
        fill-rule="evenodd"
        d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
        clip-rule="evenodd"
      />
    </svg>
  </a>
</div>
