<script>
  import { onMount, getContext } from 'svelte';
  import { createGetRequest } from '../src/create_request'
  import { time_format } from '../src/time_ago.js'
  import GuruboxNav from './gurubox_nav.svelte'

  export let click_handler
  let gurus = []
  let pagy = null
  let page = 1
  let gurusDiv

  const { imageUrl } = getContext('app')

  function fetch_gurus(page) {
    fetch(createGetRequest('/gurus.json?page='+page))
      .then(response => response.json())
      .then(data => {
        mountFromData(data)
      })
      .catch(err => {
        alert('error ' + err)
      })
  }

  function handle_click(guru) {
    console.log("gurus::handle_click " + guru)
    console.log(guru)
    click_handler(guru)

    //set active
  }

  function handle_page_nav(page) {
    console.log("gurus::handle_page_nav " + page)

    fetch_gurus(page)
  }

  function mountFromData(data, pushState = false) {
    //console.log("Number of gurus:" + data.gurus.length)
    //console.log("Paging:" + data.pagy.next)

    gurus = gurus.concat(data.gurus) // endless add
    pagy = data.pagy

    //console.log("Current gurus: " + gurus.length)
    if (pushState) {
      //window.history.pushState(zaktor.mata, zaktor.tata, '/zaktors/'+matata);
    }
  }

  onMount(async () => {
    fetch_gurus(page)
  })

</script>

<style>
  .gurubox {
    z-index: 30;
    border: 1px dashed lightgray;
    display: flex;
    justify-content: space-between;
  }
  .guruslider {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    overflow-x: scroll;
  }
  .guru {
    flex-shrink: 0;
    cursor: pointer;
    margin: 2px;
  }
  .avatar {
    display: flex;
    justify-content: center;
  }
  .avatar-img {
    border: 1px solid gray;
    border-radius: 50%;
  }
</style>

<div class="gurubox" bind:this={gurusDiv}>
  {#if (gurus.length > 0)}
    <div class="guruslider">
      {#each gurus as guru}
        <div class="guru" on:click|stopPropagation|preventDefault={handle_click(guru)}>
          <div class="avatar"><img class="avatar-img" src={imageUrl('all//'+ guru.avatar)} alt={guru.guruname}/></div>
          <div class="guruname">
            {guru.guruname}
          </div>
        </div>
      {/each}

      <GuruboxNav nav_handler={handle_page_nav} pagy={pagy}/>
    </div>
  {:else}
    No gurus...
  {/if}
</div>
