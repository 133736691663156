export function time_format(timestamp) {
  var d = new Date(timestamp * 1000)
  var tajm = d.toISOString().slice(-13, -5)
  var dejt = d.toISOString().slice(0, 10)
  return tajm + " " + dejt;
  //return d.toDateString()
}

export function time_ago(timestamp) {
  return ago(new Date(timestamp * 1000)) + ' ago'
}

function ago(val) {
  val = 0 | (Date.now() - val) / 1000;
  var unit, length = { second: 60, minute: 60, hour: 24, day: 7, week: 4.35,
      month: 12, year: 10000 }, result;

  for (unit in length) {
      result = val % length[unit];
      if (!(val = 0 | val / length[unit])) {
        return result + ' ' + (result-1 ? unit + 's' : unit);
      }
  }
}