<script>
  import { onMount } from 'svelte';
  import { createPostRequest } from '../src/create_request'

  let email = ""
  let emailInput
  let activationSent = false

  function reset() {
    activationSent=false
    email = ""
  }

  function tuneUp() {
    fetch(createPostRequest('/gurus.json',
                        'POST',
                        JSON.stringify({ guru: { email: email }})))
      .then(response => response.json())
      .then(data => {
        console.log('tuneUp:')
        console.log(data)
        activationSent = true
      })
      .catch(err => {
        alert('error ' + err)
      })
  }

  onMount(async () => {
    emailInput.focus()
  })</script>
<style>
</style>

<div class="frame">
  <h3>Tune up</h3>
  <form class="form" on:submit|preventDefault={tuneUp}>
    {#if (!activationSent)}
      <div>
        <label for="email">Email</label>
        <input id="email" name="email" type="email" required bind:value={email} bind:this={emailInput}/>
      </div>
    {/if}
    <div>
      {#if (activationSent)}
        <label>Thanks for tuning up.</label>
        <button class="btn" on:click={reset}>Tune up another?</button>
      {:else}
        <button class="btn" type="submit">Tune up</button>
      {/if}
    </div>
  </form>
</div>