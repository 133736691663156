<script>
  import { onMount } from 'svelte';
  import TitleAdder from '../src/title_adder.svelte'
  import { createPostRequest, createGetRequest, createDeleteRequest } from '../src/create_request'
  import { settings } from '../src/data_storage.js'
  settings.useLocalStorage()

  export let fetch_items_path // Ex. :"/items.json"
  export let search_items_path
  export let create_item_path
  export let show_item_path
  export let item_name = 'Shop'
  export let items_name = 'Shops'
  export let list_key = 'shops'
  export let clazz = 'shop'
  export let enable_create = false
  export let enable_show = false
  export let enable_visit = true
  export let enable_destroy = false
  export let show_handler
  export let back_handler

  let items = []
  let currentItem
  let title
  let showSelected = false

  import PagyNav from '../src/pagy_nav.svelte'
  let pagy = null
  let page = 1
  pagy = {next: true, items: items, prev: true, page: page}

  function mountFromData(data, pushState = false) {
    console.log(data)
    console.log("Number of items:" + data[list_key].length)
    console.log("Paging:" + data.pagy.count)

    items = data[list_key]
    pagy = data.pagy
  }

  function loadSettings() {
  }

  function saveSettings() {
  }

  onMount(async () => {
    fetch_items(page)
    loadSettings()
    console.log('enable_create ')
    console.log(enable_create)
  })

  function search_items(search) {
    if (search && search.length > 0) {
      console.log('search_items' + search)
      fetch(createGetRequest( search_items_path +'?search[value]='+search))
        .then(response => response.json())
        .then(data => {
          mountFromData(data)
        })
        .catch(err => {
          alert('error ' + err)
        })
    } else {
      fetch_items(1)
    }
  }

  function create_item(title) {
    if (enable_create && title.length) {
      fetch(createPostRequest(create_item_path,
                          'POST',
                          JSON.stringify({title: title})))
        .then(response => response.json())
        .then(box => {
          fetch_items(pagy.page)
        })
        .catch(err => {
          alert('error ' + err)
        })
    }
  }

  function destroy_item(item) {
    if (enable_destroy && item.id) {
      console.log('destroy_item')
      fetch(createDeleteRequest(show_item_path + '/' + item.id + '.json'))
        .then(response => {
          fetch_items(pagy.page)
        })
        .catch(err => {
          alert('error ' + err)
        })
    }
  }

  function fetch_items(page) {
    fetch(createGetRequest(fetch_items_path + '?page='+page))
      .then(response => response.json())
      .then(data => {
        mountFromData(data)
      })
      .catch(err => {
        alert('error ' + err)
      })
  }

  function choose_item(item) {
    console.log('choose_item')
    currentItem = item
    title = currentItem.title

    // saveSettings()

    showSelected = true

    if (show_handler) {
      show_handler(currentItem)
    }
  }

  function handle_update() {
    fetch_items(page)
  }

  function handle_page_next(page) {
    console.log('page next')
    fetch_items(page)
  }

  function handle_page_prev(page) {
    console.log('page prev')
    fetch_items(page)
  }

  function handle_dump(item_id) {
    console.log('handle dump')
    fetch_items(page)
  }

  function back_to_items() {
    console.log("Back to Items")
    showSelected = false

    if (back_handler) {
      fetch_items(page)
      back_handler()
    }
  }
</script>

<style>
</style>

<div class="wrap">
  {#if enable_show && showSelected}
    <div class="meta-buttons flex flex-row gap-2">
      <button class="btn" on:click={back_to_items}>Back</button>
      <div class="grow flex justify-center self-center font-bold bg-gray-100 dark:bg-black leading-8">
        {#if $$slots.title}
          <slot name="title"/>
        {:else}
          {item_name}: {currentItem.title}
        {/if}
      </div>
      {#if enable_visit}
        <a class="btn" href="{show_item_path}/{currentItem.slug || currentItem.id}">Visit</a>
      {/if}
    </div>
    <slot name="show"/>
  {:else}
    <div class="lister">
      <TitleAdder class="my-2" display_title="{items_name}:" label="New {item_name} Title" create_handler={create_item} search_handler={search_items} enable_create={enable_create}/>

      <div class="items pr-2">
        <PagyNav page_next_handler={handle_page_next} page_prev_handler={handle_page_prev} pagy={pagy}/>
        <div class="items-entries">
          {#each items as item}
            <div class="item-entry cursor-pointer border-b-2 border-gray-100 dark:border-black flex flex-row justify-between mt-2" on:click={() => choose_item(item)}>
              <slot {item}/>
              {#if item.title}
                <div class="item-entry-title font-bold">{item.title || ''}</div>
              {/if}
              {#if item.domain_url}
                <div class="item-entry-domain_url">{item.domain_url || ''}</div>
              {/if}
              {#if enable_destroy}
                <div class="item-entry-remove flex flex-row">
                  <button class="btn" on:click={(event) => {event.preventDefault(); event.stopPropagation(); destroy_item(item)}}>Remove</button>
                </div>
              {/if}
            </div>
          {/each}
        </div>
        <PagyNav page_next_handler={handle_page_next} page_prev_handler={handle_page_prev} pagy={pagy}/>
      </div>
    </div>
  {/if}
</div>
