<script>
  import { onMount } from 'svelte';
  import { slide } from 'svelte/transition'
  import { fly } from 'svelte/transition'
  import { createPostRequest } from '../src/create_request'
  import { createGetRequest } from '../src/create_request'
  import ShopboxAdder from './shopbox_adder.svelte'
  import { settings } from '../src/data_storage.js'
  settings.useLocalStorage()

  import PagyNav from '../src/pagy_nav.svelte'
  let pagy = null
  let page = 1
  let kichboxes = []
  pagy = {next: true, kichboxes: kichboxes, prev: true, page: page}

  export let shop
  let showShopboxAdder = false

  function mountFromData(data, pushState = false) {
    console.log(data)
    console.log("Number of kichboxes:" + data.kichboxes.length)
    console.log("Paging:" + data.pagy.count)

    kichboxes = data.kichboxes //.concat(data.kichboxes) // endless add
    pagy = data.pagy
  }

  function fetchKichboxes(page) {
    fetch(createGetRequest('/shops/' + shop.id + '/kichboxes.json?page='+page))
      .then(response => response.json())
      .then(data => {
        mountFromData(data)
      })
      .catch(err => {
        alert('error ' + err)
      })
  }

  onMount(async () => {
    loadSettings()

    fetchKichboxes(page)
  })

  function loadSettings() {
  }

  function saveSettings() {
  }

  function choose_kichbox(kichbox) {
    console.log('choose_kichbox')

    // saveSettings()
  }

  function rem_kichbox(kichbox) {
    console.log('rem_kichbox')
    fetch(createPostRequest('/shops/' + shop.id + '/rem_kichbox.json',
                        'POST',
                        JSON.stringify({kichbox: {id: kichbox.id}})))
      .then(response => response.json())
      .then(data => {
        fetchKichboxes(page)
      })
      .catch(err => {
        alert('error ' + err)
      })

    //saveSettings()
  }

  function handle_page_next(page) {
    console.log('page next')
    fetchKichboxes(page)
  }

  function handle_page_prev(page) {
    console.log('page prev')
    fetchKichboxes(page)
  }

  function handle_dump(kichbox_id) {
    console.log('handle dump')
    //showKichbox = false
    //showKichboxes = true
    fetchKichboxes(page)
  }

  function search_kichboxes(search) {
    if (search && search.length > 0) {
      console.log('search_kichboxes' + search)
      fetch(createGetRequest('/kichboxes/search.json?search[value]='+search))
        .then(response => response.json())
        .then(data => {
          mountFromData(data)
        })
        .catch(err => {
          alert('error ' + err)
        })
    } else {
      fetchKichboxes(1)
    }
  }

  function handle_cancel_adding() {
    console.log('handle_cancel_adding')
    showShopboxAdder = false
    fetchKichboxes(page)
  }

  function handle_update() {
    fetchKichboxes(1)
  }
</script>

<div class="relative bg-white border-t-2 border-gray-100 dark:bg-black dark:border-black dark:border-t-4 mt-3">
  <div class="absolute text-sm dark:bg-black duration-300 transform -translate-y-4 scale-75 top-1 z-10 origin-[0] bg-gray-100 px-1 font-bold peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1">Kichboxes</div>
</div>

{#if (showShopboxAdder)}
  <ShopboxAdder bind:shop={shop} cancel_handler={handle_cancel_adding} update_handler={handle_update}/>
{:else}
  <div class="menu-with-toolbar mt-4">
    <div class="flex flex-row justify-center"><button class="btn" on:click={() => {showShopboxAdder = true}}>Add Kichboxes...</button></div>

    {#if (kichboxes)}
      <div class="kichbox-menu mt-3">
        <PagyNav page_next_handler={handle_page_next} page_prev_handler={handle_page_prev} pagy={pagy}/>
        <div class="kichbox-menu-entries">
          {#each kichboxes as kichbox}
            <div class="kichbox-menu-entry border-b-2 border-gray-100 dark:border-black" on:click={() => choose_kichbox(kichbox)}>
              <div class="kichbox-menu-entry-title">{kichbox.title}</div>
              <div class="kichbox-menu-entry-info flex flex-row">
                <button class="btn" on:click={() => {rem_kichbox(kichbox)}}>Remove</button>
              </div>
            </div>
          {/each}
        </div>
        <PagyNav page_next_handler={handle_page_next} page_prev_handler={handle_page_prev} pagy={pagy}/>
      </div>
    {/if}
  </div>
{/if}

