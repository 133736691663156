<script>
  import { zaktorMatata, settings } from '../src/data_storage.js'
  zaktorMatata.useLocalStorage()
  settings.useLocalStorage()

  import { auth } from '../src/auth.js'
  import { onMount } from 'svelte';
  import { afterUpdate } from 'svelte';
  import Ilon from './ilon.svelte'
  import { createPostRequest } from '../src/create_request'
  import { createGetRequest } from '../src/create_request'
  import { createDeleteRequest } from '../src/create_request'

  import IlonNameAutoComplete from './ilonauto.svelte'

  export let zaktor = {id: ''}
  export let zaktors = []
  export let matata = '/zaktors/food/mood'
  zaktor.xilon   = ''
  zaktor.yilon   = ''
  zaktor.uniqize = true
  let matataStartsWithZaktors = false
  let zaktor_id = zaktor.id
  let pixlo = 10
  let pixlocolor = [0,100,100]
  let high_xilons = []
  let high_yilons = []
  let flach = true
  let yilon_flach = true
  let xilon_flach = true
  let yilon_relat_value = 0
  let xilon_relat_value = 0
  let yilon_relat_button_value = '+'
  let xilon_relat_button_value = '+'
  let default_background = '#bababa'
  let yilon_relat_button_background = default_background
  let xilon_relat_button_background = default_background
  let yilon_menu_selectz = false
  let xilon_menu_selectz = false
  let xilon_name = ''
  let yilon_name = ''

  let show = {
    showid: false,
    showdata: true,
    showdata_created: false,
    showdata_updated: true,
    showdata_count: false
  }

  let adding_zaktor = false
  let new_zaktor_mata = ''
  let new_zaktor_tata = ''
  let editable = $auth != null // need to be logged in to edit

  function loadSettings() {
    console.log('loadSettings')
    console.log($settings)
    if ($settings && $settings.show) {
      show = $settings.show
    }
  }

  function saveSettings() {
    if (show && $settings) {
      $settings.show = show
      console.log('saveSettings')
      console.log($settings)
    }
  }

  function mountFromData(data, pushState = false) {
    console.log('mounting from data: ' + data)
    console.log(data)
    if (data && data.zaktors && data.zaktor) {
      zaktors        = data.zaktors
      zaktor         = data.zaktor
      zaktor_id      = zaktor.id
      zaktor.xilon   = zaktor.mata;
      zaktor.yilon   = zaktor.tata;
      yilon_relat_button_value = '+' + zaktor.yilon
      xilon_relat_button_value = '+' + zaktor.xilon
      zaktor.uniqize = true
      matata = '/zaktors/' + zaktor.mata + '/' + zaktor.tata
      adding_zaktor = false
      new_zaktor_mata = ''
      new_zaktor_tata = ''

      if (pushState) {
        //instead of messing with the pushy state of the browser's history window
        //example:  window.history.pushState(zaktor.mata, zaktor.tata, matata)
        //let's use localStorage to save the current 'route'
        $zaktorMatata = matata
        //console.log('saved zaktorMatata:' + matata)
        //console.log($zaktorMatata)
      }

      loadSettings()
    }
  }

  function handleError(response) {
    if (!response.ok) throw Error(response.statusText)
    return response
  }

  onMount(async () => {
    //console.log('onMount ! mounting...')
    console.log('zaktorMatata from store:')
    console.log($zaktorMatata)
    //console.log('zaktor from storage:')
    //console.log(localStorage.getItem("matata"))

    matata = $zaktorMatata
    console.log('mounted zaktorMatata: ' + matata)
    try {
      matataStartsWithZaktors = matata.startsWith('/zaktors')
    } catch (e) {
      //console.log(e)
      matata = '/zaktors/buy/sell'
      matataStartsWithZaktors = true
    }

    if (matataStartsWithZaktors) {
      fetch(createGetRequest(matata))
        .then(handleError)
        .then(response => response.json())
        .then(data => {
          mountFromData(data)
        })
        .catch(err => {
          alert('error ' + err)
        })
    }
  })

  Array.prototype.rgbToHsb = function() {
    var red = this[0], green = this[1], blue = this[2];
    var hue, saturation, brightness;
    var max = Math.max(red, green, blue), min = Math.min(red, green, blue);
    var delta = max - min;
    brightness = max / 255;
    saturation = (max != 0) ? delta / max : 0;
    if (saturation == 0){
      hue = 0;
    } else {
      var rr = (max - red) / delta;
      var gr = (max - green) / delta;
      var br = (max - blue) / delta;
      if (red == max) hue = br - gr;
      else if (green == max) hue = 2 + rr - br;
      else hue = 4 + gr - rr;
      hue /= 6;
      if (hue < 0) hue++;
    }
    return [Math.round(hue * 360), Math.round(saturation * 100), Math.round(brightness * 100)];
  }

  Array.prototype.hsbToRgb = function() {
    var br = Math.round(this[2] / 100 * 255);
    if (this[1] == 0){
      return [br, br, br];
    } else {
      var hue = this[0] % 360;
      var f = hue % 60;
      var p = Math.round((this[2] * (100 - this[1])) / 10000 * 255);
      var q = Math.round((this[2] * (6000 - this[1] * f)) / 600000 * 255);
      var t = Math.round((this[2] * (6000 - this[1] * (60 - f))) / 600000 * 255);
      switch(Math.floor(hue / 60)){
        case 0: return [br, t, p];
        case 1: return [q, br, p];
        case 2: return [p, br, t];
        case 3: return [p, q, br];
        case 4: return [t, p, br];
        case 5: return [br, p, q];
      }
    }
    return false;
  }

  function rgb(r,g,b) { return [r,g,b] }
  function bgr(s) { return "rgb("+s[0]+","+s[1]+","+s[2]+")" }

  function fato() {
    document.querySelector('meta[name="csrf-token"]')['content'];
  }

  function colorer() {
    pixlocolor = [(pixlocolor[0]+pixlo), pixlocolor[1], pixlocolor[2]];
    pixlo+=10;
    return bgr( pixlocolor.hsbToRgb() );
  }

  function class_for_zaktor_menuitem(item) {
    return (item.id === zaktor_id) ? 'selectz zaktor' : 'zaktor'
   }

  function handle_zaktor_dump_click(id) {
    fetch(createDeleteRequest('/zaktors/'+id+'.json'))
      .then(response => response.json())
      .then(data => {
        window.location = '/zaktors/'
      })
      .catch(err => {
        alert('error ' + err)
      })
  }

  function handle_zaktor_save_click(event) {
    if (event.target) {
      fetch(createPostRequest('/zaktors.json', 'POST', JSON.stringify({zaktor: {mata: new_zaktor_mata, tata: new_zaktor_tata}})))
        .then(response => response.json())
        .then(data => {
          mountFromData(data, true)
          reseter()
        })
        .catch(err => {
          alert('error ' + err)
        })

    }
  }

  function handle_zaktor_add_click(event) {
    adding_zaktor = true
  }

  function handle_zaktor_cancel_click(event) {
    adding_zaktor = false
  }

  function handle_zaktor_menuitem_click(event) {
    //instead of document.location = event.target, we fetch
    if (event.target) {
      fetch(createGetRequest(event.target))
        .then(response => response.json())
        .then(data => {
          mountFromData(data, true)
        })
        .catch(err => {
          //alert('error ' + err)
        })

      reseter()
    }
  }

  function handle_message(event) {
    var ilon = event.detail.ilon
    if (event.detail.text === 'dump') { // delete ilon
      if (ilon.ilon_class === 'xilon') {
        var idx = zaktor.xilons.indexOf(ilon)
        zaktor.xilons.splice(idx, 1)
        zaktor.xilons = zaktor.xilons
      } else if (ilon.ilon_class === 'yilon') {
        var idx = zaktor.yilons.indexOf(ilon)
        zaktor.yilons.splice(idx, 1)
        zaktor.yilons = zaktor.yilons
      }
    }
  }

  function reseter() {
    high_yilons = []
    high_xilons = []
    xilon_flach = true
    yilon_flach = true
    yilon_relat_value = 0
    xilon_relat_value = 0
    yilon_relat_button_value = '+' + zaktor.yilon
    xilon_relat_button_value = '+' + zaktor.xilon

    document.querySelectorAll('li.ilon').forEach((ilon, index) => {
      ilon.style.background = 'transparent'
    })

    yilon_relat_button_background = '#bababa'
    xilon_relat_button_background = '#bababa'
  }

  function select_xilon_menu(event) {
    reseter()
    fetch('/zaktors/'+zaktor.id+'/xilons.json')
      .then(response => response.json())
      .then(ilons => {
        zaktor.xilons = ilons
        xilon_menu_selectz = true
        yilon_menu_selectz = false
      })
      .catch(err => {
        alert('error ' + err)
      })
  }

  function select_yilon_menu(event) {
    reseter()
    fetch('/zaktors/'+zaktor.id+'/yilons.json')
      .then(response => response.json())
      .then(ilons => {
        zaktor.yilons = ilons
        xilon_menu_selectz = false
        yilon_menu_selectz = true
      })
      .catch(err => {
        alert('error ' + err)
      })
  }

  function ilon_click_handler(ilon, ilon_class) {
    console.log('ilon click ' +  ilon.name + ' ' + ilon_class)
    if (ilon_class === 'Xilon') {
        if (high_yilons.length>0) {
          high_yilons = []
          xilon_relat_button_value = '+'+zaktor.xilon
        }

        yilon_relat_button_value = '+'+zaktor.yilon+'>>'+zaktor.xilon
        yilon_relat_value = ilon.id

        var background = colorer()
        ilon.background = background
        yilon_relat_button_background = background

        if (!high_xilons.includes(ilon.id)) {
          high_xilons = [...high_xilons, ilon.id]
        }
        fetch('/zaktors/'+zaktor_id+'/xilons/'+ilon.id+'.json')
          .then(response => response.json())
          .then(ilons => {
            ilons.forEach((elem, index) =>{
              elem.background = background
            })
            if (yilon_flach) {
              zaktor.yilons = ilons
              yilon_flach = false
            } else {
              zaktor.yilons.unshift(...ilons)
              zaktor.yilons = zaktor.yilons
            }
            xilon_menu_selectz = true
            yilon_menu_selectz = false
            if (zaktor.uniqize) uniqize('yilons')
          })
          .catch(err => {
            alert('error ' + err)
          })

    } else if (ilon_class === 'Yilon') {
        if (high_xilons.length>0) {
          high_xilons = []
          yilon_relat_button_value = '+'+zaktor.yilon
        }

        xilon_relat_button_value = '+'+zaktor.xilon+'>>'+zaktor.yilon
        xilon_relat_value = ilon.id

        var background = colorer()
        ilon.background = background
        xilon_relat_button_background = background

        if (!high_yilons.includes(ilon.id)) {
          high_yilons = [...high_yilons, ilon.id]
        }
        fetch('/zaktors/'+zaktor_id+'/yilons/'+ilon.id+'.json')
          .then(response => response.json())
          .then(ilons => {
            ilons.forEach((elem, index) =>{
              elem.background = background
            })
            if (xilon_flach) {
              zaktor.xilons = ilons
              xilon_flach = false
            } else {
              zaktor.xilons.unshift(...ilons)
              zaktor.xilons = zaktor.xilons
            }
            xilon_menu_selectz = false
            yilon_menu_selectz = true
            if (zaktor.uniqize) uniqize('xilons')
          })
          .catch(err => {
            alert('error ' + err)
          })
    }
  }

  function uniqize(ilon_class) {
    var ids = []
    var ilons = zaktor[ilon_class]
    var uniq_ilons = []

    ilons.forEach((ilon, index) => {
      var ajdi = ilon.id
      if (!ids.includes(ajdi)) {
        ids.push(ajdi)
        uniq_ilons.push(ilon)
      }
    })

    zaktor[ilon_class] = uniq_ilons
  }

  function xilon_submit() {
    fetch(createPostRequest('/zaktors/'+zaktor_id+'/xilons.json',
                        'POST',
                        JSON.stringify({relat_id: xilon_relat_value, ilon: {name: xilon_name}})))
      .then(response => response.json())
      .then(ilons => {
        console.log('submitted xilon ' + ilons.id + ' - ' + ilons.zaktor_id + ' ' + ilons.ilon_class)
        console.log(ilons)
        xilon_name = ''
        ilons.background = "transparent"
        zaktor.xilons.unshift(ilons)
        zaktor.xilons = zaktor.xilons
        if (zaktor.uniqize) uniqize('xilons')
      })
      .catch(err => {
        alert('error ' + err)
      })
  }

  function yilon_submit() {
    fetch(createPostRequest('/zaktors/'+zaktor_id+'/yilons.json',
                        'POST',
                        JSON.stringify({relat_id: yilon_relat_value, ilon: {name: yilon_name}})))
      .then(response => response.json())
      .then(ilons => {
        console.log('submitted yilon ' + ilons.id + ' - ' + ilons.zaktor_id + ' ' + ilons.ilon_class)
        console.log(ilons)
        yilon_name = ''
        ilons.background = "transparent"
        zaktor.yilons.unshift(ilons)
        zaktor.yilons = zaktor.yilons
        if (zaktor.uniqize) uniqize('yilons')
      })
      .catch(err => {
        alert('error ' + err)
      })
  }
</script>

<style>
  .yilons_controls {
    position: relative;
  }
  .xilons_controls {
    position: relative;
  }
</style>

<div id='sizr'>
  <div id='zaktor_menu'>
    <div id='zaktors'>
      <ul>
        {#each zaktors as zaktor, i}
          <li on:click|preventDefault={handle_zaktor_menuitem_click} class="{class_for_zaktor_menuitem(zaktor)}">
            <a href="/zaktors/{zaktor.mata}/{zaktor.tata}">{zaktor.mata}&{zaktor.tata}</a>
            {#if editable}
              <button class="button_dump" on:click|stopPropagation={handle_zaktor_dump_click(zaktor.id)}>X</button>
            {/if}
          </li>
        {/each}
        {#if adding_zaktor}
          <li class="zaktor add">
            <input type="text" bind:value={new_zaktor_mata} on:click|stopPropagation/> &
            <input type="text" bind:value={new_zaktor_tata} on:click|stopPropagation/>
            <button on:click|stopPropagation={handle_zaktor_save_click}>Save</button>
            <button on:click|stopPropagation={handle_zaktor_cancel_click}>X</button>
          </li>
        {:else}
          {#if editable}
            <li class="zaktor" on:click|stopPropagation={handle_zaktor_add_click}>
              +
            </li>
          {/if}
        {/if}
      </ul>
    </div>
  </div>

  <div id='xilon_menu' on:click|preventDefault={select_xilon_menu} class="menu" class:selectz={xilon_menu_selectz}>
    <span>{ zaktor.xilon }</span>
  </div>
  <div id='yilon_menu' on:click|preventDefault={select_yilon_menu} class="menu" class:selectz={yilon_menu_selectz}>
    <span>{ zaktor.yilon }</span>
  </div>

  <div id="xilon_content">
    <div id="xilons_controls">
      {#if editable}
        <IlonNameAutoComplete name="xilon_name" items="{zaktor.xilons}" bind:search={xilon_name} submit_handler={xilon_submit}/>
        <button on:click|preventDefault={xilon_submit}
                style="background-color: {xilon_relat_button_background}"
                class="submit ilon_button" type="submit" title="Dodaj {zaktor.xilon}" id="xilon_relat_button">{xilon_relat_button_value}</button>
      {/if}
    </div>
    <ul id="xilons" name="xilons">
      {#if zaktor.xilons}
        {#each zaktor.xilons as ilon, i}
          <Ilon {editable} ilon={ilon} ilon_class='Xilon' {...show}
                selected={high_xilons.includes(ilon.id)} ilon_click_handler={ilon_click_handler} on:message={handle_message}></Ilon>
        {/each}
      {/if}
    </ul>
  </div>
  <div id="yilon_content">
    <div id="yilons_controls">
      {#if editable}
        <IlonNameAutoComplete name="yilon_name" items="{zaktor.yilons}" bind:search={yilon_name} submit_handler={yilon_submit}/>
        <button on:click|preventDefault={yilon_submit}
                style="background-color: {yilon_relat_button_background}"
                class="submit ilon_button" type="submit" title="Dodaj {zaktor.yilon}" id="yilon_relat_button">{yilon_relat_button_value}</button>
      {/if}
    </div>
    <ul id="yilons" name="yilons">
      {#if zaktor.yilons }
        {#each zaktor.yilons as ilon, i}
          <Ilon {editable} ilon={ilon} ilon_class='Yilon' {...show}
                selected={high_yilons.includes(ilon.id)} ilon_click_handler={ilon_click_handler} on:message={handle_message}></Ilon>
        {/each}
      {/if}
    </ul>
  </div>
  <div id='alels' class="flex flex-row justify-center mt-4">
    <label>
      <input type=checkbox bind:checked={show.showid} on:change={saveSettings}>
      Ids
    </label>
    <label>
      <input type=checkbox bind:checked={show.showdata} on:change={saveSettings}>
      Data
    </label>
    <label>
      <input type=checkbox bind:checked={show.showdata_count} on:change={saveSettings}>
      Count
    </label>
    <label>
      <input type=checkbox bind:checked={show.showdata_created} on:change={saveSettings}>
      Created
    </label>
    <label>
      <input type=checkbox bind:checked={show.showdata_updated} on:change={saveSettings}>
      Updated
    </label>
  </div>
</div>