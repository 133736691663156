<script>
  import { onMount } from 'svelte';
  import { slide } from 'svelte/transition'
  import { fly } from 'svelte/transition'
  import { createGetRequest, createPostRequest, createUploadRequest, createDeleteRequest } from '../src/create_request'
  import Azzets from './azzets.svelte'
  import TitleAdder from '../src/title_adder.svelte'
  import InPlaceEditor from '../src/in_place_editor.svelte'
  import PagyNav from '../src/pagy_nav.svelte'
  import { settings } from '../src/data_storage.js'
  settings.useLocalStorage()

  export let kichbox
  export let choose_kich_handler
  export let update_handler
  export let back_handler
  let showKichImages = false
  let images = {}
  let imagesArray = []
  let uploadForm
  let files
  let file
  let kiches = []
  let currentKich
  let errors = false
  let showKich = false
  let addingKich = false
  let pagy = null
  let page = 1
  pagy = {next: true, kiches: kiches, prev: true, page: page}

  // kich attributes
  const KINDS = [{title: 'tangible', value: 'tangible'}, {title: 'service',  value: 'service'}]
  let kindInput
  let kind
  let titleInput
  let title
  let summaryInput
  let summary
  let storyInput
  let story
  let descriptionInput
  let description
  let priceInput
  let price
  let soldInput
  let sold
  let soldTitle

  function mountFromData(data, pushState = false) {
    console.log(data)
    console.log("Number of kiches:" + data.kiches.length)
    console.log("Paging:" + data.pagy.count)

    kiches = data.kiches
    pagy = data.pagy
  }

  function fetchKiches(page) {
    fetch(createGetRequest('/kichboxes/' + kichbox.id + '/kiches.json?page='+page))
      .then(response => response.json())
      .then(data => {
        mountFromData(data)
      })
      .catch(err => {
        alert('error ' + err)
      })
  }

  function loadSettings() {
  }

  function saveSettings() {
  }

  onMount(async () => {
    fetchKiches(page)
    loadSettings()
  })

  function handle_update(updatedKich) {
    fetchKiches(page)
    if (updatedKich) {
      choose_kich(updatedKich)
    }
  }

  function handle_title_update() {
    console.log('handle_title_update')
    currentKich.title = title

    fetch(createPostRequest('/kiches/' +  currentKich.id + '.json',
                        'PUT',
                        JSON.stringify({kich: {title: currentKich.title}})))
      .then(response => response.json())
      .then(kich => {
        if (kich.errors) {
          alert(kich.errors)
        } else {
          currentKich = kich
          if (update_handler) {
            //update_handler(value)
          }
        }
      })
      .catch(err => {
        alert('error ' + err)
      })
  }

  function handle_story_update() {
    console.log('handle_story_update')
    currentKich.story = story

    fetch(createPostRequest('/kiches/' +  currentKich.id + '.json',
                        'PUT',
                        JSON.stringify({kich: {story: currentKich.story}})))
      .then(response => response.json())
      .then(kich => {
        if (kich.errors) {
          alert(kich.errors)
        } else {
          currentKich = kich
          if (update_handler) {
            //update_handler(value)
          }
        }
      })
      .catch(err => {
        alert('error ' + err)
      })
  }

  function handle_cancel() {
    console.log('handle_cancel')
    showKich = false
  }

  function handle_back() {
    console.log('handle_back')
    if (back_handler) {
      back_handler()
    }
  }

  function choose_kich(kich) {
    currentKich = kich
    title = currentKich.title
    summary = currentKich.summary
    story = currentKich.story
    price = currentKich.price
    kind = currentKich.kind
    sold = currentKich.sold
    soldTitle = kind === 'tangible' ? 'Sold?' : 'Reserved?'
    //unit = currentKich.unit
    //locale = currentKich.locale

    showKich = true

    saveSettings()
    if (choose_kich_handler) {
      choose_kich_handler(currentKich)
    }
  }

  function new_kich() {
    console.log('new_kich')
    addingKich = true
  }

  function create_kich(new_title) {
    console.log('create_kich')
    if (new_title.length && kichbox) {
      fetch(createPostRequest('/kichboxes/' + kichbox.id + '/kiches.json',
                          'POST',
                          JSON.stringify({title: new_title})))
        .then(response => response.json())
        .then(kich => {
          if (kich.errors) {
            alert(kich.errors)
          } else {
            console.log('created kich:')
            console.log(kich)
            currentKich = kich
            addingKich = false

            fetchKiches(page)
          }
        })
        .catch(err => {
          alert('error ' + err)
        })
    }
  }

  function dump_kich() {
    fetch(createDeleteRequest('/kiches/'+currentKich.id+'.json'))
      .then(response => {
        fetchKiches(page)
        if (back_handler) {
          back_handler()
        }
      })
      .catch(err => {
        alert('error ' + err)
      })
  }

  function search_kiches(search) {
    console.log('search_kiches' + search)
    if (search && search.length > 0) {
      console.log('search_kiches' + search)
      fetch(createGetRequest('/kichboxes/' + kichbox.id + '/kiches/search.json?search[value]=' + search))
        .then(response => response.json())
        .then(data => {
          mountFromData(data)
        })
        .catch(err => {
          alert('error ' + err)
        })
    } else {
      fetchKiches(1)
    }
  }

  function handle_cancel_new_kich() {
    console.log('handle_cancel_new_kich')
    addingKich = false
  }

  function handle_page_next(page) {
    console.log('page next')
    fetchKiches(page)
  }

  function handle_page_prev(page) {
    console.log('page prev')
    fetchKiches(page)
  }

  document.addEventListener('handle_back_to_kichbox', (e) => {
    showKich = false
    clearUploadForm()
  })

  function clearUploadForm() {
    // uploadForm.reset()
    images = {}
    files = null
    file = null
  }

  function upload(event) {
    console.log('uploading...')
    //let formData = new FormData(event.target)
    //let formData = new FormData(document.querySelector("form"))
    //console.log(formData)
    let formData = new FormData();
    // let oldAndNewImages = []
    for(const img of currentKich.images) {
      console.log('existing kich[images]')
      console.log(img)
      formData.append('kich[images][]', img.signed_id)
      // oldAndNewImages.push(img.signed_id)
    }
    for(const file of files) {
      console.log('kich[images]')
      console.log(file)
      formData.append('kich[images][]', file)
      // oldAndNewImages.push(file)
    }
    // formData.append('kich[images]', oldAndNewImages)

    fetch(createUploadRequest('/kiches/'+currentKich.id+'/upload',
                        'POST',
                        formData))
      .then(response => response.json())
      .then(kich => {
        if (kich.errors) {
          alert(kich.errors)
        } else {
          console.log('uploaded kich image:')
          console.log(kich)
          currentKich = kich
          if (update_handler) {
            //update_handler(value)
          }
          clearUploadForm()
        }
      })
      .catch(err => {
        alert('error ' + err)
      })
  }

  function readImage(file) {
    var reader = new FileReader();
    reader.onload = function (event) {
      images[file.name] = {size: file.size, src: event.target.result}
    }
    reader.readAsDataURL(file)
  }

  $: if (files) {
    // Note that `files` is of type `FileList`, not an Array:
    // https://developer.mozilla.org/en-US/docs/Web/API/FileList
    console.log(files);

    for (const file of files) {
      console.log(`${file.name}: ${file.size} bytes`)
      readImage(file)
    }
  }

  let csfr
  let csrfToken = document.head.querySelector("[name='csrf-token']")
  if (csrfToken && csrfToken['content']) { csfr = csrfToken['content'] }
</script>
{#if showKich}
  <div class="mt-6">
    <InPlaceEditor bind:value={title}
                   clazz="kich"
                   name="title"
                   title="Title"
                   url={'/kiches/' + currentKich.id + '.json'}
                   update_handler={handle_update}
                   class="bg-white border-t-2 dark:bg-black dark:border-black"/>
  </div>
  <div class="mt-3">
    <InPlaceEditor bind:value={summary}
                   clazz="kich"
                   name="summary"
                   title="Summary"
                   url={'/kiches/' + currentKich.id + '.json'}
                   update_handler={handle_update}
                   class="bg-white border-t-2 dark:bg-black dark:border-black"/>
  </div>
  <div class="mt-3">
    <InPlaceEditor bind:value={story}
                   clazz="kich"
                   name="story"
                   title="Story"
                   url={'/kiches/' + currentKich.id + '.json'}
                   update_handler={handle_update}
                   type="trix"
                   class="bg-white border-t-2 dark:bg-black dark:border-black"/>
  </div>
  <div class="mt-3">
    <InPlaceEditor bind:value={price}
                   clazz="kich"
                   name="price"
                   title="Price"
                   url={'/kiches/' + currentKich.id + '.json'}
                   update_handler={handle_update}
                   type="currency"
                   class="bg-white border-t-2 dark:bg-black dark:border-black"
                   bind:locale={currentKich.locale}
                   bind:currency={currentKich.currency}/>
  </div>
  <div class="mt-3">
    <InPlaceEditor bind:value={kind}
                   clazz="kich"
                   name="kind"
                   title="Kind"
                   url={'/kiches/' + currentKich.id + '.json'}
                   update_handler={handle_update}
                   type="select"
                   class="bg-white border-t-2 dark:bg-black dark:border-black"
                   collection={KINDS}/>
  </div>

  <div class="mt-3">
    <InPlaceEditor bind:value={sold}
                   clazz="kich"
                   name="sold"
                   bind:title={soldTitle}
                   url={'/kiches/' + currentKich.id + '.json'}
                   update_handler={handle_update}
                   type="boolean"
                   class="bg-white border-t-2 dark:bg-black dark:border-black"/>
  </div>


  <Azzets kich={currentKich}/>

  {#if showKichImages}
    Images:
    <div class="kich-images flex-row flex-wrap">
      {currentKich.images.length}
      {#each currentKich.images as img, index}
        <img src={img.src} alt={img.name}/>
      {/each}
    </div>

    <form
      method="POST"
      action="{'/kiches/'+currentKich.id+'/upload'}"
      enctype="multipart/form-data"
      bind:this={uploadForm}
      on:submit|preventDefault={upload}>
      <input type="hidden" name="authenticity_token" value={csfr}/>
      {#each currentKich.images as img, index}
        <input id="existing-image-{index}" type="hidden" name="kich[images]" value={img.signed_id}/>
      {/each}
      <label for="many">Upload multiple files of any type:</label>
      <input bind:files id="many" multiple type="file" name="kich[images][]"/>
      <button type="submit" class="btn" >Upload</button>
    </form>
    <div class="mt-1">
      {#if files}
        {#each Object.keys(images) as key}
          <span class='hidden'>{file = images[key]}{imagesArray.push(file)}</span>
          <div class="kich-image flex flex-col bg-gray-100">
            <img src="{file.src}" alt="{key}" />
            <p>{key} ({file.size} bytes)</p>
          </div>
        {/each}
      {/if}
    </div>
  {/if}
  <div class="my-3 flex flex-row justify-end">
    <button class="btn" on:click={dump_kich}>Dump {currentKich.title}</button>
  </div>

{:else}
  <div class="add-kich my-1 ml-1">
    <TitleAdder class="my-6" display_title="Kiches:" label="New Kich Title" create_handler={create_kich} search_handler={search_kiches}/>
  </div>

  {#if kiches && kiches.length > 0}
    <div class="kiches pr-2">
      <PagyNav page_next_handler={handle_page_next} page_prev_handler={handle_page_prev} pagy={pagy}/>
      <div class="entries flex flex-col justify-end">
        {#each kiches as kich}
          <div class="entry cursor-pointer border-b-2 border-gray-100 flex flex-row justify-between" on:click={() => choose_kich(kich)}>
            <div class="kichbox-menu-entry-title flex flex-row justify-start items-center">{kich.title}</div>
            <div class="kichbox-menu-entry-info"><img class="object-cover h-4 w-4 m-1" src={kich.image}></div>
          </div>
        {/each}
      </div>
      <PagyNav page_next_handler={handle_page_next} page_prev_handler={handle_page_prev} pagy={pagy}/>
    </div>
  {/if}
{/if}
