<script>
  import Ilondata from './ilondata.svelte'
  import Ilonname from './ilonname.svelte'
  import { time_ago } from '../src/time_ago.js'

  export let editable = false
  export let ilon
  export let ilon_class
  export let ilon_click_handler
  export let selected = false
  export let showid = true
  export let showdata = false
  export let showdata_created = false
  export let showdata_updated = false
  export let showdata_count = false
  let ilondata_visible = false

  function handle_click(event) {
    ilon_click_handler(ilon, ilon_class)
  }

  function save_handler(ilons) {
    ilon = ilons
  }

  function toggle_ilondata(event) {
    ilondata_visible = !ilondata_visible
  }
</script>

<style>
</style>

<li class="ilon ilon-{ilon.id}"
    name={ilon.name}
    on:click|preventDefault|stopPropagation={handle_click}
    class:selectz={selected}
    style="background-color: {ilon.background}">
  <div>
    {#if ilon_class === 'Xilon'}
      {#if showdata}
        <span title="Toggle Data" on:click|preventDefault|stopPropagation={toggle_ilondata}>+++</span>
      {/if}
      {#if showdata_created }
        <span class="timer" title="Created">{time_ago(ilon.created_at_timestamp)}</span>
      {/if}
      {#if showdata_updated }
        <span class="timer" title="Updated">{time_ago(ilon.updated_at_timestamp)}</span>
      {/if}
      {#if showdata_count }
        {#if ilon.yilons_count}
          {ilon.yilons_count}
        {/if}
      {/if}
      <Ilonname {editable} {ilon} save_handler={save_handler}/>
      {#if showid}
        [{ilon.id}]
      {/if}
    {:else if ilon_class === 'Yilon'}
      {#if showid}
        [{ilon.id}]
      {/if}
      <Ilonname {editable} {ilon} save_handler={save_handler}/>
      {#if showdata_count }
        {#if ilon.xilons_count }
          {ilon.xilons_count}
        {/if}
      {/if}
      {#if showdata_updated }
        <span class="timer">{time_ago(ilon.updated_at_timestamp)}</span>
      {/if}
      {#if showdata_created }
        <span class="timer">{time_ago(ilon.created_at_timestamp)}</span>
      {/if}
      {#if showdata}
        <span title="Toggle Data" on:click|preventDefault|stopPropagation={toggle_ilondata}>+++</span>
      {/if}
  {/if}
  </div>
  {#if ilondata_visible }
    <div class="ilondata">
      <Ilondata {editable} {ilon} {save_handler} on:message/>
    </div>
  {/if}
</li>

