<script>
  import { onMount, getContext } from 'svelte'
  const { imageUrl } = getContext('app')

</script>
<div class="frame">
  <div class="mt-8 flex flex-col justify-center">
    <div class="flex flex-row justify-center text-3xl font-bold">
      Welcome!
    </div>

    <div class="m-4">
      <span class="text-xl font-bold">eternalfriend.io</span>
      <p>are you an artist, but don&rsquo;t know how to sell your art online with complex expensive tools like Etsy or Shopify? eternalfriend makes it simple and affordable. quickly build your webshop and sell unique art pieces or art work services online.</p>
    </div>

    <img src="{imageUrl('all/banner.png')}" alt="eternalfriend.io">

    <div class="m-4">
      <div class="savemail">
        <span class="text-xl font-bold">news: eternalfriend is now in private beta</span>
        <span class="text-lg">reserve your eternalfriend membership today by sending us an email with a request for an invite</span>
        <p class="p-2"><a class="btn" href="mailto:info@eternalfriend.io?subject=Eternal invitation&body=Please provide information about you and your art and explain the reason why you want to join. See you!">Request invite</a></p>
        <small>*we’ll only use your email address to send an invite to eternalfriend, not for future marketing, sales, or anything like that. we’ll never sell or trade your name or email. in fact, the list will be destroyed once everyone on the list has been invited.</small>
      </div>
    </div>
  </div>
</div>