<script>
  import { onMount } from 'svelte';
  import { settings } from '../src/data_storage.js'
  settings.useLocalStorage()

  export let address
  export let title
  export let show_address = true

  function mountFromData(data, pushState = false) {
    console.log(data)
  }

  function loadSettings() {
  }

  function saveSettings() {
  }

  onMount(async () => {
    loadSettings()
  })

  function toggleAddress() {
    show_address = !show_address
  }
</script>

<style>
</style>

<div class="font-bold cursor-pointer" on:click={toggleAddress}>{title}</div>
{#if show_address}
  <div class="border border-gray-100 dark:border-black p-4 mt-1 max-w-96">
    <div class="flex flex-row gap-2">
      <div class="address-field">{address.first_name || ''}</div>
      <div class="address-field">{address.last_name || ''}</div>
    </div>
    <div class="address-field">{address.street1 || ''}</div>
    <div class="address-field">{address.street2 || ''}</div>
    <div class="address-field">{address.zipcode || ''}</div>
    <div class="address-field">{address.state || ''}</div>
    <div class="address-field">{address.country || ''}</div>
  </div>
{/if}