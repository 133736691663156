<script>
  import { onMount } from 'svelte';

  export let peer
  let video

  onMount(async () => {
    peer.on("stream", stream => {
      if ('srcObject' in video) {
        video.srcObject = stream
        console.log('streaming video stream')
      } else {
        video.src = window.URL.createObjectURL(stream) // for older browsers
        console.log('streaming video url')
      }

      video.play()
    })
  })
</script>
<style>
  video.peerVideo {
    z-index: 40;
  }
</style>

{#if peer}
  <video class="peerVideo" bind:this={video} autoPlay playsInline controls muted></video>
{/if}