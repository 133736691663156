<script>
  export let fill
  export let alt
  export let blink = false
</script>
<style>
  .blink {
    animation: blink 1s linear infinite;
  }
  @keyframes blink {
    50% {
      opacity: 0.5;
    }
  }
</style>
<div class="flex flex-col {$$restProps.class || ''}">
  <div class="flex flex-row font-bold text-xs">{alt}</div>
  <div class="flex flex-row {blink?'blink':''}">
    <svg xmlns="http://www.w3.org/2000/svg" height="24px" width="24px" aria-label="{alt}">
      <circle r="10" cx="12" cy="12" fill="{fill}"/>
    </svg>
  </div>
</div>