<style>
:global([data-svelte-search] input) {
  width: 100%;
  padding: 0.5rem;
  margin: 0;
  border: 1px solid #e0e0e0;
  border-radius: 0.25rem;
}
</style>
<script>
  import { onMount } from "svelte";
  import { tick } from "svelte"
  import { slide } from "svelte/transition"
  import { settings } from "../src/data_storage.js"
  settings.useLocalStorage()

  import Search from "svelte-search";

  export let display_title
  export let label
  export let createBtnTitle = "Create"
  export let cancelBtnTitle = "Cancel"
  export let addBtnTitle = "Add"
  export let create_handler
  export let search_handler
  export let enable_create = true
  let titleInput
  let title
  let errors = false
  let adding = false
  let searchInput
  let search

  function mountFromData(data, pushState = false) {

  }

  function loadSettings() {
  }

  function saveSettings() {
  }

  onMount(async () => {
    // title = kich.title
    // titleInput.focus()
    loadSettings()
    console.log('titleadder enable_create')
    console.log(enable_create)
  })

  function handle_add() {
    console.log('handle_add')
    adding = true

    tick().then(() => titleInput.focus())
  }

  function handle_cancel() {
    console.log('handle_cancel')

    adding = false
  }

  function handle_create() {
    console.log('handle_create')

    adding = false
    if (create_handler) {
      create_handler(title)
    }

    title = ''
  }

  function handle_search() {
    console.log('handle_search ' + search)

    if (search_handler) {
      search_handler(search)
    }
  }
</script>
<div class="add-new {$$restProps.class || ''}">
  {#if adding}
    <div class="flex flex-row" transition:slide>
      <div class="grow mr-2">
        <label
          for={label}
          class="relative block overflow-hidden rounded-md border border-gray-100 dark:border-black dark:text-white px-3 pt-3 shadow-sm dark:focus-within:border-red-600 dark:focus-within:ring-1 dark:focus-within:ring-red-600 focus-within:border-cyan-400 focus-within:ring-1 focus-within:ring-cyan-400"
        >
          <input bind:value={title} on:click|stopPropagation bind:this={titleInput}
            type="text"
            placeholder={label}
            class="peer h-8 w-full border-none bg-transparent p-0 placeholder-transparent focus:border-transparent focus:outline-none focus:ring-0 sm:text-sm"
          />

          <span
            class="absolute start-3 top-3 -translate-y-1/2 text-xs transition-all peer-placeholder-shown:top-1/2 peer-placeholder-shown:text-sm peer-focus:top-3 peer-focus:text-xs text-gray-400"
          >
            {label}
          </span>
        </label>
      </div>
      <button class="btn mr-1" on:click|preventDefault={handle_create}>{createBtnTitle}</button>
      <button class="btn btn-alt" on:click|preventDefault={handle_cancel}>{cancelBtnTitle}</button>
    </div>
  {:else}
    <div class="flex flex-row justify-between" transition:slide>
      <div class="font-bold flex items-center">{display_title}</div>
      <div class="grow justify-center flex items-center">
        <Search class="text-black"
          hideLabel
          placeholder="Type to search..."
          debounce={1200}
          bind:value={search}
          on:type={(e) => {
            console.log("type", e.detail); // input value
            handle_search()
          }}
          on:clear={() => {
            console.log("clear");
            handle_search()
          }}/>
      </div>
      {#if enable_create === true}
        <button class="btn ml-2" on:click|preventDefault={handle_add}>{addBtnTitle}</button>
      {/if}
    </div>
  {/if}
</div>
