<script>
  //hackish unix timestamp
  function stamp() {
    return new Date / 1e3 | 0
  }

  import { onMount } from 'svelte';
  import { auth } from '../src/auth.js'
  import { createPostRequest } from '../src/create_request'
  import { createGetRequest } from '../src/create_request'
  import { createDeleteRequest } from '../src/create_request'
  import { createSimplePeer } from 'simple-peer'
  import Gurubox from './gurubox.svelte'
  import Wrdsbox from './wrdsbox.svelte'
  import PeerVideo from './peer_video.svelte'
  import consumer from '../channels/consumer'
  import Peer from 'simple-peer' // WebRTC Peer

  //let gurus = [] // friends
  let words = [] // messages
  let peers = [] //all peers connected
  let guruVideo // video element for the local video
  let guruVideoClass = ""
  let joined = "" //did this peer join anything?
  let joined_with_video = false
  let incoming = ""
  let errors = ""
  let peer = null
  let mediaStream = null

  $: guruVideoClass = joined_with_video ? "joined" : ""

  function createPeer(userToSignal, callerID, stream) {
    console.log('createPeer id:' + userToSignal + ' callerID:'+ callerID)
    var peer = new Peer({
        initiator: true,
        trickle: false,
        stream,
    });

    peer.on("signal", signal => {
      //if first ever signal send event user joined
      //else send signal
      if (signal.type === 'offer' || signal.type === 'answer') {
        wordsConsumer.speak({to: userToSignal, event: 'user joined', caller: callerID, signal: signal, timestamp: stamp() })
      } else {
        wordsConsumer.speak({to: userToSignal, event: 'signaling', caller: callerID, signal: signal, timestamp: stamp() })
      }
        //client: socketRef.current.emit("sending signal", { userToSignal, callerID, signal })
        //server: io.to(payload.userToSignal).emit('user joined', { signal: payload.signal, callerID: payload.callerID });
    })

    return peer;
  }

  function addPeer(incomingSignal, callerID, stream) {
    console.log('addPeer id: '+ $auth.id + ' callerID:'+ callerID)
    var peer = new Peer({
        initiator: false,
        trickle: false,
        stream,
    })

    peer.on("signal", signal => {
      if (signal.type === 'offer' || signal.type === 'answer') {
        wordsConsumer.speak({to: callerID, event:'receiving returned signal', signal: signal, id: $auth.id, timestamp: stamp() })
      } else {
        wordsConsumer.speak({to: callerID, event:'signaling', signal: signal, id: $auth.id, timestamp: stamp() })
      }
        //client: socketRef.current.emit("returning signal", { signal, callerID })
        //server: io.to(payload.callerID).emit('receiving returned signal', { signal: payload.signal, id: socket.id });
    })

    peer.signal(incomingSignal);

    return peer;
  }

  //called when a signal arrives via actioncable
  function received_event_via_cable(data) {
    if (data.event) {
      console.log('actioncable event: ' + data.event)
      console.log(data)
      console.log($auth)
      // needs to be processed by those who did not join the room but are already in the room
      // the ws server adds "speakers" array so we can create peers for each of those
      if (data.event === 'join room' && data.from === $auth.id) { //only the one joining should process createPeers
        console.log('join video room with speakers: ' + joined)
        console.log(data.speakers)
        data.speakers.forEach(guruId => {
          console.log('speakers loop ' + guruId)
          if (joined && guruId !== $auth.id) {
            //var item = peers.find(p => p.id === data.id);
            var item = peers.find(p => p.id === guruId);
            if (item) {
              console.log('can not createPeer, peer already exists: ' + guruId)
            } else {
              console.log('createPeer ' + guruId + ' caller:' + $auth.id)
              var peer = createPeer(guruId, $auth.id, mediaStream)
              peers = peers.concat({ id: guruId, peer: peer })
            }
          }
        })
      } else if (data.event === 'leave room' && data.from !== $auth.id) { //only the ones not leaving should process a leave
        console.log('leave video room: ' + joined)
        var peeritem = peers.find(p => p.id === data.from);
        if (peeritem) {
          peeritem.peer.destroy()
        }
        peers = peers.filter(item => item.id !== data.from)
      } else if (data.event === 'user joined' && data.to === $auth.id) {

        var item = peers.find(p => p.id === data.caller);
        if (item) {
          console.log('can not addPeer, peer already exists: ' + data.caller)

          item.peer.signal(data.signal);

        } else {
          var peer = addPeer(data.signal, data.caller, mediaStream);
          peers = peers.concat({ id: data.caller, peer: peer })
        }

      } else if (data.event === 'receiving returned signal' && data.to === $auth.id) {

        //var item = peersRef.find(p => p.peerID === data.id);
        var item = peers.find(p => p.id === data.id);
        if (item) {
          console.log('does this happen?1')
          item.peer.signal(data.signal);
        }

      } else if (data.event === 'signaling' && data.to === $auth.id) {

        //var item = peersRef.find(p => p.peerID === data.id);
        var item = peers.find(p => p.id === data.id);
        if (item) {
          console.log('does this happen?2')
          item.peer.signal(data.signal);
        }

      }

    }
  }

  let wordsConsumer = consumer.subscriptions.create({ channel: "WordsChannel", room: "motherbox" }, {
    connected() {
      // Called when the subscription is ready for use on the server
      console.log("WordsChannel connected")
      //console.log(this)
    },

    disconnected() {
      // Called when the subscription has been terminated by the server
      console.log("WordsChannel disconnected")
    },

    received(data) {
      // Called when there's incoming data on the websocket for this channel
      //console.log('received WordsChannel data: ')
      //console.log(data)
      if (data.words) {
        words.unshift(data)
        words = words
      } else if (data.event) {
        //send event signal into chat so other can copy paste?
        console.log(words)
        words.unshift(data)
        words = words

        received_event_via_cable(data)
      } else if (data.join) {
        if (data.join === 'motherbox') {
          join(data)
        }
      } else if (data.leave) {
        if (data.leave === 'motherbox') {
          leave(data)
        }
      }
    },

    speak: function(data) {
      //console.log("WordsChannel speak")
      return this.perform('speak', data);
    }
  });

  //Send text over actioncable
  function handle_words_send(msg) {
    //console.log("handle_words_send " + msg)
    wordsConsumer.speak({words: msg, timestamp: stamp(), guru_session: $auth})

    // alternatively
    // send text over webrtc
    // peers[0..x].send(incoming)
  }

  function playVideoWith(stream) {
    //console.log('playVideoWith')
    //console.log(stream)
    if ('srcObject' in guruVideo) {
      guruVideo.srcObject = stream
    } else {
      guruVideo.src = window.URL.createObjectURL(stream) // for older browsers
    }

    guruVideo.onloadedmetadata = () => {
      guruVideo.play()
    }
  }

  function join_room_with_video(stream) {
    //console.log('saveMedia')
    mediaStream = stream
    playVideoWith(mediaStream)

    // don't create the peer here,
    // instead send a "join room" into websocket
    joined = 'motherbox' // TODO room will be the current brickbox
    wordsConsumer.speak({from: $auth.id, event: 'join room', timestamp: stamp(), guru_session: $auth})
  }

  function joinRoomWithVideo() {
    navigator.mediaDevices.getUserMedia({
      video: true,
      audio: true
    }).then(join_room_with_video).catch(() => {})
  }

  function handle_join_video() {
    console.log('handle_join_video')
    //show video element
    joined_with_video = true

    joinRoomWithVideo()
  }

  function handle_leave_video() {
    console.log('handle_leave_video')
    //hide video element
    joined_with_video = false

    // notify everyone about leaving
    wordsConsumer.speak({from: $auth.id, event: 'leave room', timestamp: stamp(), guru_session: $auth})

    // effectively shut down camera/mic
    mediaStream.getTracks().forEach(track => track.stop());

    //destroy all peers
    peers.forEach(item => {
      item.peer.destroy()
    })
    peers = []
  }

  function handle_guru_click(data) {
    console.log('handle_guru_click')
  }

  function mountFromData(data, pushState = false) {
    //console.log(data)
    //console.log("Number of gurus:" + data.length)

    //gurus = data.gurus

    if (pushState) {
      //window.history.pushState(zaktor.mata, zaktor.tata, '/zaktors/'+matata);
    }
  }

  onMount(async () => {
    //fetch(createGetRequest('/gurus.json'))
    //  .then(response => response.json())
    //  .then(data => {
    //    mountFromData(data)
    //  })
    //  .catch(err => {
    //    alert('error ' + err)
    //  })
  })

</script>

<style>
  .videos {
    position: absolute;
    top: 210px;
    width: 100%;
    bottom: 55px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    background-color: transparent;
    z-index: 5;
  }
  video {
    z-index: 10;
    width: 100%;
  }
  video.guruVideo {
    display: none;
    z-index: 10;
  }
  video.guruVideo.joined {
    display: block;
  }
  form.toolbar {
    @apply font-semibold text-sm text-gray-500 m-2;
    display: flex;
    flex-direction: row;
    justify-content: end;
    z-index: 40;
  }
</style>

<div class="frame">

  <Gurubox click_handler={handle_guru_click}/>
  {#if joined_with_video }
    <form class="toolbar">
      <div class="btns">
        <button class="btn" on:click|preventDefault={handle_leave_video}>Leave video</button>
      </div>
    </form>
  {:else}
    <form class="toolbar">
      <div class="btns">
        <button class="btn" on:click|preventDefault={handle_join_video}>Join with video</button>
      </div>
    </form>
  {/if}
  <div class="videos">
    <video class="guruVideo {guruVideoClass}" controls muted bind:this={guruVideo} autoPlay playsInline />
    {#each peers as peerItem }
      <PeerVideo peer={peerItem.peer} />
    {/each}
  </div>
  <Wrdsbox msg={incoming} messages={words} save_handler={handle_words_send}/>
</div>
