<script>
  import { onMount } from 'svelte';
  import { slide } from 'svelte/transition'
  import { fly } from 'svelte/transition'
  import {tick} from 'svelte'
  import { createGetRequest } from '../src/create_request'
  import { createPostRequest } from '../src/create_request'
  import { createUploadRequest } from '../src/create_request'
  import InPlaceEditor from '../src/in_place_editor.svelte'
  import Azzet from './azzet.svelte'
  import { settings } from '../src/data_storage.js'
  settings.useLocalStorage()

  export let kich
  export let update_handler

  let azzets = []
  let images = {}
  let imagesArray = []
  let uploadForm
  let fileInput
  let file // temp
  let selectedFiles
  let uploadingFile
  let errors = false
  let titleInput
  let title
  let description

  function mountFromData(data, pushState = false) {
    console.log(data)
    azzets = data.azzets
  }

  function loadSettings() {
  }

  function saveSettings() {
  }

  function fetchAzzets() {
    console.log('fetchAzzets')
    fetch(createGetRequest('/kiches/' + kich.id + '/azzets.json'))
      .then(response => response.json())
      .then(data => {
        mountFromData(data)
      })
      .catch(err => {
        alert('error ' + err)
      })
  }

  onMount(async () => {
    loadSettings()
    fetchAzzets()
  })

  function handle_update() {
  }

  function handle_title_update() {
    console.log('handle_title_update')
    kich.title = title

    fetch(createPostRequest('/kiches/' + kich.id + '.json',
                        'PUT',
                        JSON.stringify({kich: {title: kich.title}})))
      .then(response => response.json())
      .then(data => {
        if (data.errors) {
          alert(data.errors)
        } else {
          kich = data
          if (update_handler) {
            //update_handler(value)
          }
        }
      })
      .catch(err => {
        alert('error ' + err)
      })
  }

  function handle_cancel() {
    console.log('handle_cancel')
  }

  function handle_back() {
    console.log('handle_back')
    if (back_handler) {
      back_handler()
    }
  }

  function clearUploadForm() {
    uploadForm.reset()
    images = {}
    file = null
  }

  function upload(event) {
    console.log('uploading...')
    let formData = new FormData();
    // for(const file of files) {
    //  console.log('azzet files')
    //  console.log(file)
    //  formData.append('azzet[files][]', file)
    // }
    formData.append('azzet[file]', fileInput.files[0])
    formData.append('azzet[title]', fileInput.files[0].name)

    fetch(createUploadRequest('/kiches/'+kich.id+'/azzets/upload',
                        'POST',
                        formData))
      .then(response => response.json())
      .then(data => {
        if (data.errors) {
          alert(data.errors)
        } else {
          console.log('uploaded kich image:')
          console.log(data)
          kich.azzets.push(data)
          azzets = kich.azzets
          if (update_handler) {
            // update_handler(value)
          }
          clearUploadForm()
        }
      })
      .catch(err => {
        alert('error ' + err)
      })
  }

  function readImage(file) {
    var reader = new FileReader();
    reader.onload = function (event) {
      images[file.name] = {size: file.size, src: event.target.result}
      uploadingFile = {size: file.size, src: event.target.result}
    }
    reader.readAsDataURL(file)
  }

  // when file input is populated run this reactive code to read and display images before they get uploaded
  $: if (selectedFiles) {
    // Note that `files` is of type `FileList`, not an Array:
    // https://developer.mozilla.org/en-US/docs/Web/API/FileList
    console.log(fileInput.files[0])
    // console.log(`${selectedFile.name}: ${selectedFile.size} bytes`)
    readImage(fileInput.files[0])
  }

  let previewDiv
  function scrollIntoView() {
    console.log("scrollIntoView1")
    //const el = target.element
    //if (!el) return
    setTimeout(() => {
      if (previewDiv) {
        console.log("scrollIntoView2")
        previewDiv.scrollIntoView({behavior: 'smooth'})
      }
    }, 1000)
  }

  let csfr
  let csrfToken = document.head.querySelector("[name='csrf-token']")
  if (csrfToken && csrfToken['content']) { csfr = csrfToken['content'] }
</script>

<div class="azzets-wrap relative border-t-2 bg-white border-t-2 dark:bg-black dark:border-black mt-3">
  <div class="absolute text-sm duration-300 transform -translate-y-4 scale-75 top-1 z-10 origin-[0] bg-gray-100 dark:bg-black px-1 font-bold peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-1 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1">Images</div>
  <div class="azzets flex flex-row flex-wrap gap-1 mt-4">
    {#if (azzets)}
      {#each azzets as azzet, index}
        <Azzet azzet={azzet}/>
      {/each}
    {/if}
  </div>
</div>
<div class="upload-form p-2 dark:bg-black">
  <form method="POST"
        action="{'/kiches/'+kich.id+'/upload'}"
        enctype="multipart/form-data"
        bind:this={uploadForm}
        on:submit|preventDefault={upload}>
    <input type="hidden" name="authenticity_token" value={csfr}/>
    <input bind:this={fileInput} bind:value={selectedFiles} id="azzet-upload" type="file" name="azzet[file]" on:click={() => {images = {}}}/>

    <div class="mt-1">
      {#if selectedFiles}
        {#each Object.keys(images) as key}
          <span class='hidden'>{file = images[key]}{console.log(file)} {imagesArray.push(file)}</span>
          <div bind:this={previewDiv} class="kich-image flex flex-col p-4" use:scrollIntoView>
            <div class="mb-4 flex flex-row justify-between">
              <strong>Previewing:</strong> {key} ({file.size} bytes)
              <button type="submit" class="btn">Upload</button>
            </div>
            <img src="{file.src}" alt="{key}" />
          </div>
        {/each}
      {/if}
    </div>

  </form>
</div>

