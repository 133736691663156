<script>
  import { onMount } from 'svelte';
  import { createGetRequest } from '../src/create_request'
  import { settings } from '../src/data_storage.js'
  settings.useLocalStorage()
  import Lister from '../src/lister.svelte'
  import InPlaceEditor from '../src/in_place_editor.svelte'

  let currentShop
  let showing = false
  let currentPage
  let showingPage = false
  let currentBlog
  let showingBlog = false

  function mountFromData(data, pushState = false) {
  }

  onMount(async () => {

  })

  function handle_show(item) {
    currentShop = item
    showing = true
    console.log('handle_show')
    console.log(currentShop)
  }

  function handle_list(item) {
    showing = false
    console.log('handle_list')
  }

  function handle_show_page(item) {
    currentPage = item
    showingPage = true
    console.log('handle_show_page')
    console.log(currentPage)
  }

  function handle_list_page(item) {
    showingPage = false
    console.log('handle_list_page')
  }

  function handle_webpage_update() {
    console.log('handle_webpage_update')
  }

  function handle_show_blog(item) {
    currentBlog = item
    showingBlog = true
    console.log('handle_show_blog')
    console.log(currentBlog)
  }

  function handle_list_blog(item) {
    showingBlog = false
    console.log('handle_list_blog')
  }

  function handle_blog_update() {
    console.log('handle_blog_update')
  }
</script>

<style>
</style>

<Lister fetch_items_path = '/shops.json'
        search_items_path = '/shops/search.json'
        create_item_path = '/shops.json'
        show_item_path = '/shops'
        item_name = 'Shop'
        items_name = 'Choose shop'
        list_key = 'shops'
        enable_show = {true}
        enable_create = {false}
        show_handler = {handle_show}
        back_handler = {handle_list}>
  <div slot="show">
    {#if showing && currentShop}
      <Lister fetch_items_path = {'/shops/' + currentShop.id + '/webpages.json'}
              search_items_path = {'/shops/' + currentShop.id + '/webpages/search.json'}
              create_item_path = {'/shops/' + currentShop.id + '/webpages.json'}
              show_item_path = {'/shops/' + currentShop.id + '/webpages'}
              item_name = 'Webpage'
              items_name = 'Choose webpage'
              list_key = 'webpages'
              enable_show = {true}
              enable_create = {true}
              show_handler = {handle_show_page}
              back_handler = {handle_list_page}>
        <div slot="show">
          {#if showingPage && currentPage}
            <div class="mt-6">
              <InPlaceEditor bind:value={currentPage.content}
                             clazz="webpage"
                             name="content"
                             title="Content"
                             url={'/shops/' + currentShop.id + '/webpages/' + currentPage.id + '.json'}
                             update_handler={handle_webpage_update}
                             type="trix"
                             class="bg-white border-t-2 dark:bg-black dark:border-black"/>
            </div>
            <Lister fetch_items_path = {'/shops/' + currentShop.id + '/webpages/' + currentPage.id + '/blogs.json'}
                    search_items_path = {'/shops/' + currentShop.id + '/webpages/' + currentPage.id + '/blogs/search.json'}
                    create_item_path = {'/shops/' + currentShop.id + '/webpages/' + currentPage.id + '/blogs.json'}
                    show_item_path = {'/' + currentShop.slug + '/' + currentPage.slug}
                    item_name = 'Blog'
                    items_name = 'Choose blog'
                    list_key = 'blogs'
                    clazz = 'blog'
                    enable_show = {true}
                    enable_create = {true}
                    enable_destroy = {true}
                    show_handler = {handle_show_blog}
                    back_handler = {handle_list_blog}>
              <div slot="show">
                {#if showingBlog && currentBlog}
                  <div class="mt-6">
                    <InPlaceEditor bind:value={currentBlog.title}
                                   clazz="blog"
                                   name="title"
                                   title="Title"
                                   url={'/shops/' + currentShop.id + '/webpages/' + currentPage.id + '/blogs/' + currentBlog.id + '.json'}
                                   update_handler={handle_blog_update}
                                   type="text"
                                   class="bg-white border-t-2 dark:bg-black dark:border-black"/>
                  </div>
                  <div class="mt-6">
                    <InPlaceEditor bind:value={currentBlog.summary}
                                   clazz="blog"
                                   name="summary"
                                   title="Summary"
                                   url={'/shops/' + currentShop.id + '/webpages/' + currentPage.id + '/blogs/' + currentBlog.id + '.json'}
                                   update_handler={handle_blog_update}
                                   type="trix"
                                   class="bg-white border-t-2 dark:bg-black dark:border-black"/>
                  </div>
                  <div class="mt-6">
                    <InPlaceEditor bind:value={currentBlog.content}
                                   clazz="blog"
                                   name="content"
                                   title="Content"
                                   url={'/shops/' + currentShop.id + '/webpages/' + currentPage.id + '/blogs/' + currentBlog.id + '.json'}
                                   update_handler={handle_blog_update}
                                   type="trix"
                                   class="bg-white border-t-2 dark:bg-black dark:border-black"/>
                  </div>
                {/if}
              </div>
            </Lister>
          {/if}
        </div>
      </Lister>
    {/if}
  </div>
</Lister>


