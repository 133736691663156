<script>
  import { settings } from '../src/data_storage.js'
  settings.useLocalStorage()
  import { createEventDispatcher, onMount } from 'svelte'
  import { createPostRequest } from '../src/create_request'
  import { focus, formatCurrency } from '../src/utils'

  export let shop, required = true
  export let update_handler

  let value
  let form
  let input
  let errors
  let field = 'settings'
  const dispatch = createEventDispatcher()

  let editing = false, original
  let submitted = false
  let title = "Settings"

  onMount(() => {
    value = shop.settings_yaml
    console.log("shop_settings onMount")
    console.log(value)
    original = value
  })

  function edit() {
    editing = true
    submitted = false
  }

  function submit() {
    console.log('shop_settings in place submit')

    if (value != original) {
      dispatch('submit', value)
    }

    editing = false
  }

  function keydown(event) {
    if (event.key == 'Escape') {
      event.preventDefault()
      value = original
      editing = false
    }
  }

  function handle_update() {
    console.log('shop_settings handle_update')
    if (submitted) {
      return
    }

    submitted = true

    if (value && value !== original) {
      let updateData = {shop: {settings: value}}
      fetch(createPostRequest('/shops/'+shop.id+'.json',
                          'PUT',
                          JSON.stringify(updateData)))
        .then(response => response.json())
        .then(data => {
          if (data.errors) {
            errors = data.errors
            //alert(data.errors)
            submitted = false
          } else {
            editing = false
            errors = null
            if (update_handler) {
              update_handler(updateData)
            }
          }
        })
        .catch(err => {
          alert('error ' + err)
        })
    } else {
      console.log('resetting value to original')
      value = original
      submitted = false
      editing = false
    }
  }
  //$: if (value != original) {console.log('value: ' + value)}
</script>
<style>
  pre {
    @apply text-xs;
  }
</style>
{#if editing}
  <div class="relative {$$restProps.class || ''}">
    <label for="inplace-input" class="absolute text-sm duration-300 transform -translate-y-4 scale-75 top-1 z-10 origin-[0] bg-gray-100 dark:bg-black font-bold px-1 peer-focus:px-2 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-1 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1">{title}</label>
    <textarea id="inplace-input" bind:value {required} use:focus class="dark:bg-black h-64 block mt-4 px-2.5 pb-2.5 pt-4 w-full text-sm border-1 border-gray-300 appearance-none focus:outline-none focus:ring-1 focus:border-blue-600 peer"/>
    <div class="translate-y-1 flex flex-row justify-end"><button type="submit" class="btn m-1 mb-2" on:click={handle_update}>Save</button></div>
  </div>
  {#if (errors && errors[field])}({errors[field]}){/if}
  {#if (errors && errors['base'])}
    <div class="translate-y-4 text-red-400 mb-6">
      <label/>
      <span>{errors['base']}</span>
    </div>
  {/if}
{:else}
  <div on:click={edit} class="relative {$$restProps.class || ''}">
    <div class="absolute text-sm duration-300 transform -translate-y-4 scale-75 top-1 z-10 origin-[0] bg-gray-100 dark:bg-black px-1 font-bold peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-1 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1">{title}</div>
    <div class="dark:bg-black block px-2 pb-2 pt-4 w-full text-sm border-1 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer h-10 overflow-hidden"><pre>{value || 'Click here to edit '}</pre></div>
  </div>
{/if}
