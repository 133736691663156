<script>
  import { onMount, getContext } from 'svelte';

  export let pagy = {}
  export let nav_handler
  const { imageUrl } = getContext('app')

  function handle_more(){
    nav_handler(pagy.next)
  }
</script>

<style>
</style>

<nav aria-label="pager" class="pagy_nav pagination" role="navigation">
  {#if (pagy.next)}
    <span class="page next"><a aria-label="next" href="gurus" on:click|preventDefault={handle_more}><img src={imageUrl('all//di.gif')} alt="Load more gurus"/></a></span>
  {/if}
</nav>
