<script>
  import { settings } from '../src/data_storage.js'
  settings.useLocalStorage()
  import Address from '../src/address.svelte'
  import { _ } from 'svelte-i18n'
  import { formatCurrency } from '../src/utils.js'

  export let order
  let print = false

  function togglePrint() {
    print = !print
  }
</script>

{#if print}
  <div class="absolute top-0 left-0 z-50 w-full h-full bg-white dark:bg-black">
    <div class="m-6 border border-gray-100 dark:border-black">
      {#if order.shipping_address}
        <div class="mt-3">
          <Address title='Ship to:' address={order.shipping_address} show_address={true}/>
        </div>
      {/if}

      <div class="mt-3">
        {#each order.order_items as item}
          <div class="flex flex-row items-center justify-end gap-2">
            <div class="kich-title {item.kich.kind}">{item.kich.title}</div>
            {#if item.kich.kind === 'tangible'}
              <div>{formatCurrency(item.kich.price, $settings.locale, $settings.currency, 2, 0)}</div>
            {:else}
              <div>{formatCurrency(0, $settings.locale, $settings.currency, 2, 0)}</div>
            {/if}
          </div>
        {/each}
        <div class="flex flex-col border-t-2 mt-1">
          <div class="flex flex-row justify-end gap-4 mt-1">
            <div class="flex items-center">{$_('total')}: {formatCurrency(order.total, $settings.locale, $settings.currency, 2, 0)}</div>
          </div>
        </div>
      </div>

      Packing slip content
    </div>
    <div class="flex flex-row justify-center"><a href="/shops/{order.shop_id}/orders/{order.id}/slip" target="_blank" class="btn mt-32">Print</a></div>
    <div class="flex flex-row justify-center"><button class="btn mt-10" on:click={togglePrint}>Close</button></div>
    <button class="btn" on:click={togglePrint}>Print packing slip</button>
  </div>
{:else}
  <a href="/shops/{order.shop_id}/orders/{order.id}/slip" target="_blank" class="btn mt-32">Print packing slip</a>
{/if}