<script>
  import { onMount } from 'svelte';
  import { _ } from 'svelte-i18n'
  import Shopboxes from './shopboxes.svelte'
  import Shopsections from './shopsections.svelte'
  import TitleAdder from '../src/title_adder.svelte'
  import InPlaceEditor from '../src/in_place_editor.svelte'
  import ShopSettings from './shop_settings.svelte'
  import ImageEditor from '../src/image_editor.svelte'
  import { createGetRequest } from '../src/create_request'
  import { createPostRequest } from '../src/create_request'
  import { settings } from '../src/data_storage.js'
  settings.useLocalStorage()

  let shops = []
  let currentShop
  let showShop = false
  let title
  let domain_url

  import PagyNav from '../src/pagy_nav.svelte'
  let pagy = null
  let page = 1
  pagy = {next: true, shops: shops, prev: true, page: page}

  function mountFromData(data, pushState = false) {
    console.log(data)
    console.log("Number of shops:" + data.shops.length)
    console.log("Paging:" + data.pagy.count)

    shops = data.shops
    pagy = data.pagy
  }

  function fetchShops(page) {
    fetch(createGetRequest('/shops.json?page='+page))
      .then(response => response.json())
      .then(data => {
        mountFromData(data)
      })
      .catch(err => {
        alert('error ' + err)
      })
  }

  function loadSettings() {
  }

  function saveSettings() {
  }

  onMount(async () => {
    fetchShops(page)
    loadSettings()
  })

  function choose_shop(shop) {
    console.log('choose_shop')
    currentShop = shop
    title = currentShop.title
    domain_url = currentShop.domain_url

    // saveSettings()

    showShop = true
  }

  function handle_update() {
    fetchShops(page)
  }

  function create_shop(title) {
    if (title.length) {
      fetch(createPostRequest('/shops.json',
                          'POST',
                          JSON.stringify({title: title})))
        .then(response => response.json())
        .then(box => {
          fetchShops(pagy.page)
        })
        .catch(err => {
          alert('error ' + err)
        })
    }
  }

  function handle_page_next(page) {
    console.log('page next')
    fetchShops(page)
  }

  function handle_page_prev(page) {
    console.log('page prev')
    fetchShops(page)
  }

  function handle_dump(shop_id) {
    console.log('handle dump')
    fetchShops(page)
  }

  function search_shops(search) {
    if (search && search.length > 0) {
      console.log('search_shops' + search)
      fetch(createGetRequest('/shops/search.json?search[value]='+search))
        .then(response => response.json())
        .then(data => {
          mountFromData(data)
        })
        .catch(err => {
          alert('error ' + err)
        })
    } else {
      fetchShops(1)
    }
  }

  function back_to_shops() {
    console.log("Back to Shops")
    showShop = false
  }
</script>

<style>
</style>

<div class="wrap">
  {#if showShop}
    <div class="meta-buttons flex flex-row gap-2">
      <button class="btn" on:click={back_to_shops}>Back</button>
      <div class="grow flex justify-center self-center font-bold rounded bg-gray-100 dark:bg-black leading-8">Shop</div>
      <a class="btn" href="/{currentShop.slug}">Visit</a>
    </div>
    <div class="meta">
      <div class="mt-6">
        <InPlaceEditor bind:value={currentShop.title}
                       clazz="shop"
                       name="title"
                       title="Title"
                       url={'/shops/' + currentShop.id + '.json'}
                       update_handler={handle_update}
                       class="bg-white border-t-2 dark:bg-black dark:border-black"/>
      </div>
      <div class="mt-3">
        <InPlaceEditor bind:value={currentShop.domain_url}
                       clazz="shop"
                       name="domain_url"
                       title="Domain Url"
                       url={'/shops/' + currentShop.id + '.json'}
                       update_handler={handle_update}
                       class="bg-white border-t-2 dark:bg-black dark:border-black"/>
      </div>
      <div class="mt-6">
        <InPlaceEditor bind:value={currentShop.meta_title}
                       clazz="shop"
                       name="meta_title"
                       title="Meta Title"
                       url={'/shops/' + currentShop.id + '.json'}
                       update_handler={handle_update}
                       class="bg-white border-t-2 dark:bg-black dark:border-black"/>
      </div>
      <div class="mt-3">
        <InPlaceEditor bind:value={currentShop.meta_description}
                       clazz="shop"
                       name="meta_description"
                       title="Meta Description"
                       url={'/shops/' + currentShop.id + '.json'}
                       update_handler={handle_update}
                       class="bg-white border-t-2 dark:bg-black dark:border-black"/>
      </div>
      <div class="mt-6 mb-3">
        <ShopSettings bind:shop={currentShop} update_handler={handle_update} class="bg-white border-t-2 dark:bg-black dark:border-black"/>
      </div>

      <div class="mt-6 mb-3">
        <ImageEditor bind:shop={currentShop} bind:image={currentShop.logo_light} title="Logo (light)" name="logo_light" update_handler={handle_update} class="bg-white border-t-2 dark:bg-black dark:border-black"/>
      </div>

      <div class="my-3">
        <ImageEditor bind:shop={currentShop} bind:image={currentShop.logo_dark} title="Logo (dark)" name="logo_dark" update_handler={handle_update} class="bg-white border-t-2 dark:bg-black dark:border-black"/>
      </div>

    </div>
    <div class="shopboxes mt-6">
      <Shopboxes shop={currentShop}/>
    </div>
    <div class="shopsections mt-6">
      <Shopsections shop={currentShop}/>
    </div>
  {:else}
    <div class="menu-with-toolbar">
      <TitleAdder class="mt-2 mb-6" display_title="Shops:" label="New Shop Title" create_handler={create_shop} search_handler={search_shops}/>

      <div class="shops pr-2">
        <PagyNav page_next_handler={handle_page_next} page_prev_handler={handle_page_prev} pagy={pagy}/>
        <div class="shops-entries">
          {#each shops as shop}
            <div class="shop-entry cursor-pointer border-b-2 border-gray-100 dark:border-black flex flex-row justify-between items-center mt-2" on:click={() => choose_shop(shop)}>
              <div class="shop-entry-title font-bold">{shop.title}</div>
              <div class="shop-entry-domain_url">{shop.domain_url || ''}</div>
            </div>
          {/each}
        </div>
        <PagyNav page_next_handler={handle_page_next} page_prev_handler={handle_page_prev} pagy={pagy}/>
      </div>
    </div>
  {/if}
</div>

