<script>
  import { onMount } from 'svelte';
  import { slide } from 'svelte/transition'
  import { fly } from 'svelte/transition'
  import { createPostRequest } from '../src/create_request'
  import InPlaceEditor from '../src/in_place_editor.svelte'
  import { settings } from '../src/data_storage.js'
  settings.useLocalStorage()

  export let azzet
  export let update_handler

  let errors = false
  let titleInput
  let title
  let description

  let showLarge = false

  function mountFromData(data, pushState = false) {
    console.log(data)
    title = azzet.title || 'Title'
  }

  function loadSettings() {
  }

  function saveSettings() {
  }

  onMount(async () => {
    mountFromData(azzet)
    loadSettings()
  })

  function handle_title_update() {
    console.log('handle_title_update')
    azzet.title = title

    fetch(createPostRequest('/kiches/' + azzet.kich_id + '/azzets/' + azzet.id + '.json',
                        'PUT',
                        JSON.stringify({azzet: {title: title}})))
      .then(response => response.json())
      .then(data => {
        if (data.errors) {
          alert(data.errors)
        } else {
          azzet = data
          if (update_handler) {
            //update_handler(value)
          }
        }
      })
      .catch(err => {
        alert('error ' + err)
      })
  }

  function toggleLarge() {
    showLarge = !showLarge
  }
</script>

<div class="azzet flex flex-col jsutify-center items-center rounded border-2 border-gray-400 dark:border-red-800 dark:border p-2 m-2">
  {#if (showLarge)}
    <img src={azzet.azzet_url} alt={azzet.title} on:click={toggleLarge} class="rounded"/>
    <div class="azzet-title flex flex-row justify-center w-full mt-3">
      <InPlaceEditor title='Title' bind:value={title} on:submit={handle_title_update} class="bg-white border-t-2 dark:bg-black dark:border-black w-full"/>
    </div>
  {:else}
    <img src={azzet.azzet_url} alt={azzet.title} width="100px" on:click={toggleLarge} class="rounded" />
    <span class="text-xs mt-2">{title}</span>
  {/if}
</div>


