// To see this message, add the following to the `<head>` section in your
// views/layouts/application.html.erb
//
//    <%= vite_client_tag %>
//    <%= vite_javascript_tag 'application' %>
console.log('Vite ⚡️ Rails')

// If using a TypeScript entrypoint file:
//     <%= vite_typescript_tag 'application' %>
//
// If you want to use .jsx or .tsx, add the extension:
//     <%= vite_javascript_tag 'application.jsx' %>

console.log('Visit the guide for more information: ', 'https://vite-ruby.netlify.app/guide/rails')

// Import styling
// import '~/flata/style.scss'

// import tailwindcss from 'tailwindcss'

// Example: Load Rails libraries in Vite.
//
// import * as Turbo from '@hotwired/turbo'
// Turbo.start()

// import ActiveStorage from '@rails/activestorage'
// ActiveStorage.start()

// Import all channels.
//const channels = import.meta.globEager('./**/*_channel.js')

// Example: Import a stylesheet in app/frontend/index.css
// import '~/index.css'

// import "@hotwired/turbo-rails"
// import "~/channels"

// import "~/controllers"

// import "svelte"

// console.log('loading stimulus')
// import "../controllers"
// import "./channels"
import ahoy from 'ahoy.js'
import Eternal from '../eternal.svelte'

ahoy.track('eternal')
console.log('BASE_URL:')
console.log(import.meta.env.BASE_URL)

document.addEventListener('DOMContentLoaded', () => {
  const eternal = new Eternal({
    target: document.getElementById('eternalbridge'),
    props: {
      name: 'Eternal'
    }
  });

  window.eternal = eternal;
})

/*
document.addEventListener('DOMContentLoaded', () => {
  const zaktor_app = new ZaktorApp({
    target: document.body,
    props: {
      name: 'Eternal',
      matata: matata
    }
  });

  window.zaktor_app = zaktor_app;
})
*/