<script>
  import EmVideo from '../src/emvideo.svelte'
  import { onMount } from 'svelte';

  export let words
  let transformed_words = ''

  function createYoutubeEmbed(key) {
    return '<iframe width="420" height="345" src="https://www.youtube.com/embed/' + key + '" frameborder="0" allowfullscreen></iframe><br/>';
  }

  function transform(text){
    if (!text) return text;

    const linkreg = /(?:)<a([^>]+)>(.+?)<\/a>/g;
    const fullreg = /(https?:\/\/)?(www\.)?(youtube\.com\/watch\?v=|youtu\.be\/)([^& \n<]+)(?:[^ \n<]+)?/g;
    const regex = /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/watch\?v=|youtu\.be\/)([^& \n<]+)(?:[^ \n<]+)?/g;

    let resultHtml = text;

    // get all the matches for youtube links using the first regex
    const match = text.match(fullreg);
    if (match && match.length > 0) {
      // get all links and put in placeholders
      const matchlinks = text.match(linkreg);
      if (matchlinks && matchlinks.length > 0) {
        for (let i=0; i < matchlinks.length; i++) {
          resultHtml = resultHtml.replace(matchlinks[i], "#placeholder" + i + "#");
        }
      }

      // now go through the matches one by one
      for (let i=0; i < match.length; i++) {
        // get the key out of the match using the second regex
        let matchParts = match[i].split(regex);
        // replace the full match with the embedded youtube code
        resultHtml = resultHtml.replace(match[i], createYoutubeEmbed(matchParts[1]));
      }

      // ok now put our links back where the placeholders were.
      if (matchlinks && matchlinks.length > 0) {
        for (let i=0; i < matchlinks.length; i++) {
          resultHtml = resultHtml.replace("#placeholder" + i + "#", matchlinks[i]);
        }
      }
    }
    return resultHtml;
  }

  onMount(async () => {
    if (words) {
      transformed_words = transform(words)
    }
  })
</script>

{@html transformed_words}
