<script>
  import { createPostRequest } from '../src/create_request'
  import { slide } from 'svelte/transition'
  import { fly } from 'svelte/transition'
  import Kichbox from './kichbox.svelte'
  import KichesEditor from '../kich/kiches.svelte'
  import KichEditor from '../kich/editor.svelte'
  import TitleAdder from '../src/title_adder.svelte'
  import { onMount } from 'svelte';
  import { createGetRequest } from '../src/create_request'
  import { time_format } from '../src/time_ago.js'
  import { settings } from '../src/data_storage.js'
  settings.useLocalStorage()

  import PagyNav from '../src/pagy_nav.svelte'
  let pagy = null
  let page = 1
  let kichboxes = []
  let currentKichbox
  let currentKich
  pagy = {next: true, kichboxes: kichboxes, prev: true, page: page}

  let menu
  let editor
  let kichEditorVisible = false
  let newKichbox = {title: ''}
  let kichboxTitle
  let showKichboxes = true
  let showKichbox = false

  function mountFromData(data, pushState = false) {
    console.log(data)
    console.log("Number of kichboxes:" + data.kichboxes.length)
    console.log("Paging:" + data.pagy.count)

    kichboxes = data.kichboxes //.concat(data.kichboxes) // endless add
    pagy = data.pagy

    if (currentKichbox) {
      // refresh data
      let updatedBox = kichboxes.find((box)=> box.id === currentKichbox.id)
      if (updatedBox) {
        currentKichbox = updatedBox
        console.log('refreshed currentKichbox! ' + currentKichbox.kiches.length)
      }
    }
  }

  function fetchKichboxes(page) {
    fetch(createGetRequest('/kichboxes.json?page='+page))
      .then(response => response.json())
      .then(data => {
        mountFromData(data)
      })
      .catch(err => {
        alert('error ' + err)
      })
  }

  onMount(async () => {
    loadSettings()

    fetchKichboxes(page)
  })

  function loadSettings() {
    if ($settings && $settings.currentKichbox) {
      currentKichbox = $settings.currentKichbox
      if (currentKichbox.currentKich) {
        currentKich = currentKichbox.currentKich
      }
    }
  }

  function saveSettings() {
    if (currentKichbox) {
      $settings.currentKichbox = currentKichbox
      if (currentKich) {
        $settings.currentKichbox.currentKich = currentKich
      } else {
        $settings.currentKichbox.currentKich = null
      }
    }
  }

  function choose_kichbox(kichbox) {
    console.log('choose_kichbox')
    currentKichbox = kichbox

    saveSettings()
    // edit_kichbox()
    show_kichbox()
  }

  function choose_kich(kich) {
    console.log('choose_kich')
    currentKich = kich
    kichEditorVisible = true

    saveSettings()
  }

  function edit_kichbox() {
    showKichbox = false
    showKichboxes = false
  }

  function show_kichbox() {
    showKichbox = true
    showKichboxes = false
  }

  function canceled_kich_edit() {
    currentKich = null
    kichEditorVisible = false
  }

  function updated_kich() {
    currentKich = null
    kichEditorVisible = false

    //TODO: persist kich
  }

  function back_to_kichboxes() {
    console.log("Back to Kichboxes")
    showKichbox = false
    showKichboxes = true
  }

  function update_kichbox(kichbox) {
    console.log("update_kichbox: ")
    console.log(currentKichbox)
    console.log(kichbox)
    fetch(createPostRequest('/kichboxes/' + currentKichbox.id + '.json',
                        'PUT',
                        JSON.stringify({kichbox: {title: currentKichbox.title, description: currentKichbox.description}})))
      .then(response => response.json())
      .then(box => {
        currentKichbox = box
        // kichboxEditorVisible = false
        // showKichbox = true
        // showKichboxes = true
        fetchKichboxes(pagy.page)
      })
      .catch(err => {
        alert('error ' + err)
      })
  }

  function create_kichbox(title) {
    if (title.length) {
      fetch(createPostRequest('/kichboxes.json',
                          'POST',
                          JSON.stringify({title: title})))
        .then(response => response.json())
        .then(box => {
          currentKichbox = box
          fetchKichboxes(pagy.page)
        })
        .catch(err => {
          alert('error ' + err)
        })
    }
  }

  function handle_page_next(page) {
    console.log('page next')
    fetchKichboxes(page)
  }

  function handle_page_prev(page) {
    console.log('page prev')
    fetchKichboxes(page)
  }

  function handle_dump(kichbox_id) {
    console.log('handle dump')
    //showKichbox = false
    //showKichboxes = true
    fetchKichboxes(page)
  }

  function search_kichboxes(search) {
    if (search && search.length > 0) {
      console.log('search_kichboxes' + search)
      fetch(createGetRequest('/kichboxes/search.json?search[value]='+search))
        .then(response => response.json())
        .then(data => {
          mountFromData(data)
        })
        .catch(err => {
          alert('error ' + err)
        })
    } else {
      fetchKichboxes(1)
    }
  }
</script>

<div class="wrap">
  <div class="kichboxes flex flex-column">
    <div class="kichbox-content flex flex-column sm:order-last sm:w-full" bind:this={editor}>
      {#if currentKichbox}
        {#if showKichbox}
          <Kichbox on:dump={handle_dump} bind:kichbox={currentKichbox} back_handler={back_to_kichboxes} update_handler={update_kichbox}/>
        {/if}
      {/if}
    </div>
    {#if showKichboxes}
      <div class="menu-with-toolbar">
        <TitleAdder display_title="Kichboxes:" label="New Kichbox Title" create_handler={create_kichbox} search_handler={search_kichboxes} class="mb-6"/>

        <div class="kichbox-menu pr-2" bind:this={menu}>
          <PagyNav page_next_handler={handle_page_next} page_prev_handler={handle_page_prev} pagy={pagy}/>
          <div class="kichbox-menu-entries">
            {#each kichboxes as kichbox}
              <div class="kichbox-menu-entry border-b-2 border-gray-100" class:kichbox-current={currentKichbox && currentKichbox.title === kichbox.title} on:click={() => choose_kichbox(kichbox)}>
                <div class="kichbox-menu-entry-title">{kichbox.title}</div>
                <div class="kichbox-menu-entry-info"><img class="object-cover h-4 w-4" src={kichbox.image}></div>
              </div>
            {/each}

          </div>
          <PagyNav page_next_handler={handle_page_next} page_prev_handler={handle_page_prev} pagy={pagy}/>
        </div>
      </div>
    {/if}
  </div>
</div>
