<script>

const regExpEscape = (s) => {
  return s.replace(/[-\\^$*+?.()|[\]{}]/g, "\\$&")
}

export let submit_handler
let input
let list

let name = ''
export let value = ''
let placeholder = ''
let required = false
let disabled = false

// autocomplete props
export let items = []
let isOpen = false
let results = []
export let search = ''
let isLoading = false
let arrowCounter = 0

// options
export let klass = 'input'
let isAsync = false
let minChar = 1
let maxItems = 10
let fromStart = true // Default type ahead

function onChange (event) {
  console.log('onChange ' + search)

  // Is the data given by an outside ajax request?
  if (isAsync) {
    isLoading = true
    console.log('isLoading true')
  } else if (search.length >= Number(minChar)) {
    console.log('filter...')
    filterResults()
    isOpen = true
  } else {
    console.log('none of the above')
  }
}

function filterResults () {
  console.log('filterResults ' + items.length + ' search: ' + search + ' value: ' + value)
  results = items.filter(item => {
    return fromStart ? item.name.toUpperCase().startsWith(search.toUpperCase())
                     : item.name.toUpperCase().includes(search.toUpperCase())
  })
  .map(item => {
    const text = item.name
    return {
      key: text,
      value: text,
      label: search.trim() === '' ? text : text.replace(RegExp(regExpEscape(search.trim()), 'i'), "<span>$&</span>")
    }
  })

  //console.log('results length ' + results.length)

  const height = results.length > maxItems ? maxItems : results.length
  list.style.height = `${height * 2.25}rem`
}

function onKeyDown (event) {
  //console.log('onKeyDown ' + event.keyCode)
  if (event.keyCode === 40 && arrowCounter < results.length) {
    // ArrowDown
    arrowCounter = arrowCounter + 1
  } else if (event.keyCode === 38 && arrowCounter > 0) {
    // ArrowUp
    arrowCounter = arrowCounter - 1
  } else if (event.keyCode === 13) {
    // Enter
    event.preventDefault()
    if (arrowCounter === -1) {
      arrowCounter = 0 // Default select first item of list
    }
    if (!isOpen) {
      if (submit_handler) {
        submit_handler()
      }
    }
    close(arrowCounter)
  } else if (event.keyCode === 27) {
    // Escape
    event.preventDefault()
    close()
  }
}

function close(index = -1) {
  isOpen = false
  arrowCounter = -1
  //if (input) {
    //input.blur()
  //}
  if (index > -1) {
    var result = results[index]
    if (result) {
      search = result.key
      value = result.value
    } else {
      index = -1 // no results, set index back to -1
    }
  } else if (!value) {
    search = ''
  }
  //console.log('close s:' + search + ' v:' + value)
}

</script>

<style>
  .autocomplete {
    display: inline-block;
  }

  .hide-results {
    display: none;
  }

  .autocomplete-results {
    padding: 0;
    margin: 0;
    /*border: 1px solid #dbdbdb;*/
    height: 6rem;
    overflow: auto;
    width: 100%;

    background-color: white;
    box-shadow: 2px 2px 24px rgba(0, 0, 0, 0.1);
    position: absolute;
    z-index: 10;
  }

  .autocomplete-result {
    color: #7a7a7a;
    list-style: none;
    text-align: left;
    height: 2rem;
    padding: 0.25rem 0.5rem;
    cursor: pointer;
  }

  .autocomplete-result > :global(span) {
    background-color: none;
    color: #242424;
    font-weight: bold;
  }

  .autocomplete-result.is-active,
  .autocomplete-result:hover {
    background-color: #dbdbdb;
  }
</style>

<div on:click|stopPropagation class="autocomplete">
  <input
    type="text"
    class="{klass}"
    {name}
    {placeholder}
    {required}
    {disabled}
    bind:value="{search}"
    autocomplete="{name}"
    on:input={onChange}
    on:keydown={onKeyDown}
    bind:this={input}
  >
  <ul class="autocomplete-results{isOpen ? '' : ' hide-results'}" bind:this={list}>
    {#if isOpen}
    {#each results as result, i}
      <li on:click|preventDefault|stopPropagation={() => close(i)} class="autocomplete-result{ i === arrowCounter ? ' is-active' : '' }">
        {@html result.label}
      </li>
    {/each}
    {/if}
  </ul>
  {#if isLoading}
    <slot>
      <p class="fallback">Loading data...</p>
    </slot>
  {/if}
</div>

<svelte:window on:click={close()}/>
