<script>
  import { createPostRequest, createDeleteRequest } from '../src/create_request'
  import { createEventDispatcher } from 'svelte';

  export let editable = false
  export let ilon
  export let save_handler
  let editing = false
  const dispatch = createEventDispatcher();

  function handle_save(event) {
    editing = false

    fetch(createPostRequest('/zaktors/'+ilon.zaktor_id+'/'+ilon.ilon_class+'s/'+ilon.id+'.json',
                        'PUT',
                        JSON.stringify({ilon: {data: ilon.data}})))
      .then(response => response.json())
      .then(ilons => {
        ilon = ilons
        save_handler(ilons)
      })
      .catch(err => {
        alert('error ' + err)
      })
  }

  function handle_edit(event) {
    if (editable) {
      editing = true
    }
  }

  function handle_dump(event) {
    fetch(createDeleteRequest('/zaktors/'+ilon.zaktor_id+'/'+ilon.ilon_class+'s/'+ilon.id+'.json'))
      .then(response => response.json())
      .then(ilons => {
        dispatch('message', {
          text: 'dump',
          ilon: ilon
        });
      })
      .catch(err => {
        alert('error ' + err)
      })
  }
</script>

<style>
</style>

<div class="ilondata">
  {#if editing }
    <div class="editor">
      <textarea bind:value={ilon.data} on:click|stopPropagation></textarea>
    </div>
    <button on:click|stopPropagation={handle_save}>Save</button>
  {:else}
    <div class="data" on:click|stopPropagation={handle_edit}>
      {#if ilon.data}
        {ilon.data}
      {/if}
      {#if editable}
        <button on:click|stopPropagation={handle_edit}>Edit</button>
        <button on:click|stopPropagation={handle_dump}>Dump</button>
      {/if}
    </div>
  {/if}
</div>