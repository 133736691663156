<script>
  import { onMount } from 'svelte';
  import SimpleLabel from '../src/simple_label.svelte'
  import { createPostRequest } from '../src/create_request'
  import { focus } from '../src/utils'

  import { createEventDispatcher } from 'svelte';
  const dispatch = createEventDispatcher();

  import { auth } from '../src/auth.js'
  let frendword = ""
  let email = ""
  let emailInput
  let errors = false

  function tuneIn() {
    fetch(createPostRequest('/guru_sessions.json',
                        'POST',
                        JSON.stringify({guru_session: {email: email, password: frendword}})))
      .then(response => response.json())
      .then(data => {
        console.log('tuneIn:')
        console.log(data)
        if (data['errors']) {
          errors = data['errors']
        } else {
          errors = false
          dispatch('tunein', {
            text: 'tunedin',
            guru_session: data['guru_session']
          })
        }
      })
      .catch(err => {
        alert('error ' + err)
      })
  }

  function handle_cancel() {
    console.log('handle_cancel')
    window.location = '/'
  }

  onMount(async () => {
  })
</script>

<style>
</style>

<div class="frame">
  <h3>Tune in</h3>
  <form class="form" on:submit|preventDefault={tuneIn}>
    <div>
      <SimpleLabel field="email" text="Email" errors={errors}/>
      <input id="email" name="email" type="email" bind:value={email} bind:this={emailInput} use:focus autocomplete />
    </div>
    <div>
      <SimpleLabel field="password" text="Password" errors={errors}/>
      <input id="password" name="password" type="password" bind:value={frendword} />
    </div>
    <div class="btns gap-2">
      <button type="submit" class="btn">Tune in</button>
    </div>
    {#if (errors && errors['base'])}
      <div>
        <label/>
        <span>{errors['base']}</span>
      </div>
    {/if}
  </form>
</div>