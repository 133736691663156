<script>
  import {tick} from 'svelte'
  import { time_format } from '../src/time_ago.js'
  import { onMount, beforeUpdate, afterUpdate } from 'svelte';
  import Wrds from './wrds.svelte'

  export let save_handler
  export let messages
  export let msg
  export let brickbox //channel
  let empty = true
  let wrdsScrollable
  let msgInput
  let sendButton
  let sendButtonEnabled = false
  let placeholder = 'Type message here...'

  function handle_save(event) {
    console.log("wrds::handle_save " + msg + msg.length)
    if (msg.length > 0) {
      save_handler(msg)

      msg = ''
      msgInput.innerHTML = msg
      msgInput.focus()
      sendButtonEnabled = false
      console.log("wrdsScrollable: scrollTop:" + wrdsScrollable.scrollTop  +  " scrollHeight:" + wrdsScrollable.scrollHeight + " height:" + wrdsScrollable.height)

      // Not needed, since the words flow down to the oldest
      // setTimeout(()=>{
        //wrdsScrollable.scrollTop = wrdsScrollable.scrollHeight;
      // }, 10)
    }
  }

  function keyup() {
    msg = msgInput.innerHTML
    sendButtonEnabled = msg.length > 0
  }

  function keydown(event) {
    if (event.which === 13 && event.shiftKey === false) {
      event.preventDefault()
      handle_save()
    }
  }

  function msgInputClick(event) {
    console.log('clicked input')
    console.log(event)
  }

  onMount(async () => {
    brickbox = ''
    tick().then(() => msgInput.focus())
  })
</script>

<style>
  .wrdsbox-wrap {
    flex-grow: 1;
    z-index: 30;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  [contenteditable][placeholder]:empty:before {
    content: attr(placeholder);
    color: gray;
    background-color: transparent;
  }

  [contenteditable="true"]:focus {
    padding: 10px;
  }
</style>
<div class="wrdsbox-wrap">
  <div class="wrdsbox">
    <div class="wrds-editor-wrap">
      <div class="wrds-editor">
        <div class="wrds-editor-input">
          <div class="break-all outline-gray-100" contenteditable="true" placeholder="{placeholder} #{brickbox}" on:click|stopPropagation={msgInputClick} on:keyup={keyup} on:keydown={keydown} bind:this={msgInput}></div>
        </div>
        <div class="wrds-editor-controls">
          <button class="btn wrds-editor-button {sendButtonEnabled ? 'enabled' : ''}" on:click={handle_save}>Send</button>
        </div>
      </div>
    </div>
    {#key messages}
      <div class="wrds" bind:this={wrdsScrollable}>
        {#if (messages.length > 0)}
          {#each messages as wrd}
            {#if wrd.event}
            {:else}
              <div class="wrd">
                <div class="avatar"></div>
                <div class="wrdinner">
                  <div class="wrdhead"><div class="name">{wrd.guru_session.name}</div><div class="timestamp">{time_format(wrd.timestamp)}</div></div>
                  <div class="wrdbody break-all">
                    {#if wrd.words}
                      <Wrds words={wrd.words}/>
                    {/if}
                  </div>
                </div>
              </div>
            {/if}
          {/each}
        {/if}
      </div>
    {/key}
  </div>
</div>
