<script>
  import { onMount } from 'svelte';
  import {tick} from 'svelte'
  import { createGetRequest } from '../src/create_request'
  import { createDeleteRequest } from '../src/create_request'
  import { createPostRequest } from '../src/create_request'
  import SimpleLabel from '../src/simple_label.svelte'
  import Kiches from './kiches.svelte'
  import { createEventDispatcher } from 'svelte';
  const dispatch = createEventDispatcher();

  import { settings } from '../src/data_storage.js'
  settings.useLocalStorage()

  import Trix from "trix"

  export let kichbox
  export let update_handler
  export let back_handler

  let editing = false

  let errors = false
  let titleInput
  let title
  let descriptionInput
  let description

  let currentKich
  let showKiches = true
  let showKich = false
  let showMeta = false
  let editingKich = false

  function mountFromData(data, pushState = false) {
    console.log(data)
    // console.log("Number of kiches:" + data.kiches.length)
    // console.log("Paging:" + data.pagy.count)

    // kiches = data.kiches //.concat(data.kichboxes) // endless add
    // pagy = data.pagy
  }

  function loadSettings() {
  }

  function saveSettings() {
  }

  onMount(async () => {
    title = kichbox.title
    description = kichbox.description

    loadSettings()
  })

  function handle_dump(event) {
    fetch(createDeleteRequest('/kichboxes/'+kichbox.id+'.json'))
      .then(response => response.json())
      .then(dump => {
        editing = false
        showKiches =false
        showKich = false
        if (back_handler) {
          back_handler()
        }
        dispatch('dump', {
          id: kichbox.id
        })
      })
      .catch(err => {
        alert('error ' + err)
      })
  }

  function handle_edit() {
    console.log('handle_edit')
    editing = true
    tick().then(() => titleInput.focus())
  }

  function handle_cancel() {
    console.log('handle_cancel')
    editing = false
  }

  function handle_update() {
    console.log('handle_update')
    editing = false
    kichbox.title = title
    kichbox.description = descriptionInput.value // making Trix work
    description = kichbox.description // making Trix work
    if (update_handler) {
      update_handler(kichbox)
    }
  }

  function handle_back() {
    console.log('handle_back')
    editing = false
    if (back_handler) {
      back_handler()
    }
  }

  function back_to_kichbox() {
    console.log("Back to Kichbox")
    editing = false
    showKiches = true
    showKich = false
    const event = new CustomEvent('handle_back_to_kichbox')
    document.dispatchEvent(event)
  }

  function updated_kich(value) {
    console.log("updated_kich")
    if (update_handler) {
      update_handler()
    }
  }

  function choose_kich(kich) {
    console.log('choose_kich')
    showKich = true
    showMeta = false
    currentKich = kich
  }

  function handle_meta_toggle() {
    showMeta = !showMeta
  }
</script>

<div class="kichbox-meta">
  {#if showKich}
    <div class="kichbox-meta-buttons flex flex-row gap-2">
      <button class="btn" on:click={back_to_kichbox}>Back</button>
      <div class="grow flex justify-center self-center font-bold rounded bg-gray-100 dark:bg-black leading-8">Kich</div>
    </div>
  {:else}
    <form on:submit|preventDefault={handle_update}>
      <div class="kichbox-meta-buttons flex flex-row justify-between gap-2">
        {#if editing}
          <button class="btn" on:click|preventDefault={handle_cancel}>Cancel</button>
          <button type="submit" class="btn" on:click={handle_update}>Save</button>
        {:else}
          <button class="btn" on:click={handle_back}>Back</button>
          <div class="top-toolbar grow font-bold flex flex-row justify-between gap-2">
            <div class="grow flex justify-center items-center rounded bg-gray-100 dark:bg-black"><span class="font-normal mr-1">Kichbox:</span>{kichbox.title}</div>
            <div class="mr-1">
              {#if showMeta}
                <button class="btn" on:click={handle_meta_toggle}>&veebar;</button>
              {:else}
                <button class="btn" on:click={handle_meta_toggle}>&barvee;</button>
              {/if}
            </div>
          </div>
          <button class="btn" on:click={handle_edit}>Edit</button>
        {/if}
      </div>
      {#if editing}
        <div class="my-2">
          <label
            for="Title"
            class="relative block overflow-hidden rounded-md border border-gray-200 px-3 pt-3 shadow-sm focus-within:border-gray-600 focus-within:ring-1 focus-within:ring-blue-600"
          >
            <input bind:value={title} on:click|stopPropagation bind:this={titleInput}
              id="title"
              name="title"
              type="text"
              placeholder="Title"
              class="peer h-8 w-full border-none bg-transparent p-0 placeholder-transparent focus:border-transparent focus:outline-none focus:ring-0 sm:text-sm"
            />
            <span
              class="absolute start-3 top-3 -translate-y-1/2 text-xs text-gray-700 transition-all peer-placeholder-shown:top-1/2 peer-placeholder-shown:text-sm peer-focus:top-3 peer-focus:text-xs"
            >
              Title
            </span>
          </label>
        </div>
        <div class="my-2">
          <input id="description" name="description" type="hidden" bind:value={description} bind:this={descriptionInput}/>
          <trix-editor input="description"/>
        </div>
        {#if (errors && errors[field])}({errors[field]}){/if}
        {#if (errors && errors['base'])}
          <div>
            <label/>
            <span>{errors['base']}</span>
          </div>
        {/if}
      {/if}
    </form>
    {#if editing}
      <div class="flex flex-row justify-end mt-2">
        <button class="btn" on:click|stopPropagation={handle_dump}>Dump</button>
      </div>
    {/if}
  {/if}
  {#if !editing}
    {#if showMeta}
      <img src={kichbox.image}/>
      <div class="kichbox-meta-description mt-1 mx-1">
        {@html kichbox.description}
      </div>
    {/if}
    {#if showKiches}
      <Kiches bind:kichbox={kichbox} choose_kich_handler={choose_kich} back_handler={back_to_kichbox} update_handler={updated_kich}/>
    {/if}
  {/if}
</div>
