<script>
  import { createPostRequest } from '../src/create_request'

  export let editable = false
  export let ilon
  export let save_handler
  let editing = false

  function handle_save(event) {
    editing = false

    fetch(createPostRequest('/zaktors/'+ilon.zaktor_id+'/'+ilon.ilon_class+'s/'+ilon.id+'.json',
                        'PUT',
                        JSON.stringify({ilon: {name: ilon.name}})))
      .then(response => response.json())
      .then(ilons => {
        save_handler(ilons)
      })
      .catch(err => {
        alert('error ' + err)
      })
  }

  function handle_edit(event) {
    if (editable) {
      editing = true
    }
  }
</script>

<style>
</style>

{#if editing }
  <input type="text" bind:value={ilon.name} on:click|stopPropagation/>
  <button on:click|stopPropagation={handle_save}>Save</button>
{:else}
  <span class="data" on:click|stopPropagation={handle_edit}>
    {#if ilon.name}
      {ilon.name}
    {/if}
  </span>
{/if}
