<style>
</style>

<script>
  import ahoy from 'ahoy.js'
  import { onMount, setContext, getContext } from 'svelte'

  import { createGetRequest, createDeleteRequest } from './src/create_request'
  import { settings } from './src/data_storage.js'
  settings.useLocalStorage()

  import { initLocale } from './src/i18n.js'
  initLocale()

  import { auth } from './src/auth.js'

  import ShopsApp from './shops/shops.svelte'
  import WebpagesApp from './webpages/webpages.svelte'
  import ZaktorApp from './zaktor/zaktor.svelte'
  import FrendApp from './frends/frends.svelte'
  import KichboxesApp from './kichboxes/kichboxes.svelte'
  import OrdersApp from './orders/orders.svelte'
  import TuneinApp from './tune/tunein.svelte'
  import TuneupApp from './tune/tuneup.svelte'
  import EternalfriendApp from './home/eternal_friend.svelte'

  export let name = 'Eternal'

  const KdefaultCurrentApp = 'EternalfriendApp'
  const KtuneinApp = 'TuneinApp'
  const KtuneupApp = 'TuneupApp'
  const Klocale = 'sl-SI'
  const Kcurrency = 'EUR'
  let currentApp = KdefaultCurrentApp
  let currentRoute = 'welcome'
  let currentLocale = Klocale
  let currentCurrency = Kcurrency
  let menuDroppedDown = false
  let tunedIn = false
  let showFooter = true
  let y = 0 // scroll position

  // Share the scroll position with all children
  setContext('app', { getScrollY, imageUrl })

  function getScrollY() {
    return y
  }

  function imageUrl(absolutePath) {
    return import.meta.env.BASE_URL + absolutePath
  }

  function handle_menu_click(app, route = '') {
    currentApp = app
    currentRoute = route
    menuDroppedDown = false

    saveSettings()
  }

  function handle_tunein(event) {
    if (event.detail.text === 'tunedin') {
      $auth = event.detail.guru_session
      console.log('handle_tunein:')
      console.log($auth)
      window.history.pushState('root', 'root', '/');

      if (currentApp === KtuneinApp) {
        if ($settings &&
            $settings.app !== KtuneinApp &&
            $settings.app !== KtuneupApp) {
          currentApp = $settings.app
        } else {
          currentApp = KdefaultCurrentApp
        }
      }

      //chooseApp()
      //chooseAppFromLocation()
    }
  }

  function handle_tuneup(event) {
    currentApp = KtuneupApp
  }

  function handle_dropout(event) {
    fetch(createDeleteRequest('/guru_sessions.json'))
      .then(response => {
        dropOut()
      })
      .catch(err => {
        alert('error ' + err)
      })
  }

  function dropOut() {
    $auth = null
    currentApp = KdefaultCurrentApp
    currentRoute = 'goodbye'
  }

  function saveSettings() {
    $settings.app = currentApp
    $settings.route = currentRoute
    $settings.locale = currentLocale
    $settings.currency = currentCurrency
    //console.log('saveSettings: ')
    //console.log(settings)
  }

  function chooseRoute() {
    if ($settings && $settings.route) {
      currentRoute = $settings.route
    } else {
      currentRoute = 'welcome'
    }
  }

  // call this only if the guru session exists
  // choose current app
  function chooseApp() {
    if ($settings && $settings.app) {
      currentApp = $settings.app
    } else {
      currentApp = KdefaultCurrentApp
    }

    chooseRoute()

    saveSettings()
  }

  //routes, when not logged in
  function chooseAppFromLocationNonAuth() {
    if (window.location.pathname.startsWith('/tunein')) {
      currentApp = KtuneinApp
    }
  }

  function mountFromData(data, pushState = false) {
    console.log('mountFromData')
    //console.log(data)
    $auth = data['guru_session']
    console.log($auth)

    ahoy.track('eternal mount')

    chooseApp()
    //chooseAppFromLocation()
  }

  onMount(async () => {
    //console.log('eternal onMount')
    //console.log(settings)
    //console.log(localStorage.getItem("settings"))

    // fetch guru session and mount app
    fetch(createGetRequest('/guru_sessions.json'))
      .then(response => response.json())
      .then(data => {
        if (data['guru_session']) {
          mountFromData(data) // we have a previous session, we're logged in
        } else {
          // need to tune back in; we don't have a previous session
          if ($auth !== null) {
            currentApp = KtuneinApp
          } else {
            // show the default PagesApp or TuneinApp
            chooseAppFromLocationNonAuth()
          }
        }
      })
      .catch(err => {
        $auth = null
        alert('error ' + err)
      })
  });

  $: tunedIn = $auth !== null
  $: tuneInfo = tunedIn ? $auth.name : ''
  $: showFooter = currentApp === 'ZaktorApp'
</script>

<svelte:window bind:scrollY={y} />

<div class="eter" eternalname={name}>
  <header id="header" class="flex border-b border-gray-100 dark:border-cyan-100">

    <div class="header-logo flex flex-row">
      <a class="logo flex items-center justify-center" on:click|preventDefault={() => handle_menu_click('FrendApp')} href="/friends">
        <img class="hidden dark:block h-[37px]" src="{imageUrl('all/logo-alt-dark.png')}" alt="logo">
        <img class="block dark:hidden h-[37px]" src="{imageUrl('all/logo-alt.png')}" alt="logo">
      </a>
      <div class="header-title font-bold flex items-center justify-center text-sm sm:text-lg">
        {currentApp.split('App')[0]}
      </div>
    </div>

    <div class="grow"></div>

    <div class="mr-1 flex items-center justify-center text-xs sm:text-sm">{tuneInfo}</div>

    <div class="header-menu flex flex-row items-center">

      <nav id="nav" class="z-50">

        <input class="trigger peer" type="checkbox" id="mainNavButton" bind:checked={menuDroppedDown}>
        <label for="mainNavButton" class="relative block mx-1 lg:hidden"></label>

        <ul class="absolute hidden bg-white dark:bg-[#272727] peer-checked:z-50 peer-checked:flex peer-checked:flex-row peer-checked:flex-wrap peer-checked:min-h-32 peer-checked:place-content-center peer-checked:space-x-4 peer-checked:my-4 peer-checked:shadow peer-checked:right-0 peer-checked:top-8 peer-checked:w-screen lg:relative lg:flex lg:flex-row lg:flex-wrap lg:justify-end lg:mt-0">

          {#if tunedIn}
            <li><div class="underline p-1 flex items-center justify-center"><a on:click|preventDefault={() => handle_menu_click('ZaktorApp')} href="/zaktors">Zaktor</a></div></li>
            <li><div class="underline p-1 flex items-center justify-center"><a on:click|preventDefault={() => handle_menu_click('FrendApp')} href="/frends">Friends</a></div></li>
            <li><div class="underline p-1 flex items-center justify-center"><a on:click|preventDefault={() => handle_menu_click('KichboxesApp')} href="/kichboxes">Kichboxes</a></div></li>
            <li><div class="underline p-1 flex items-center justify-center"><a on:click|preventDefault={() => handle_menu_click('WebpagesApp', 'about')} href="/webpages">Webpages</a></div></li>
            <li><div class="underline p-1 flex items-center justify-center"><a on:click|preventDefault={() => handle_menu_click('ShopsApp', 'bisell')} href="/pages">Shops</a></div></li>
            <li><div class="underline p-1 flex items-center justify-center"><a on:click|preventDefault={() => handle_menu_click('OrdersApp')} href="/orders">Orders</a></div></li>
            <li><div class="underline p-1 flex items-center justify-center"><a on:click|preventDefault={handle_dropout} href="/">Dropout</a></div></li>
          {:else}
            <li><div class="underline p-1 flex items-center justify-center"><a on:click|preventDefault={() => handle_menu_click(KtuneinApp)} href="/tunein">Tune in</a></div></li>
          {/if}
        </ul>
      </nav>
    </div>
  </header>

  <main>
    {#if currentApp === KtuneinApp}
      <TuneinApp on:tunein={handle_tunein}/>
    {:else if currentApp === KtuneupApp}
      <TuneupApp/>
    {:else if currentApp === 'FrendApp'}
      <FrendApp/>
    {:else if currentApp === 'ZaktorApp'}
      <ZaktorApp/>
    {:else if currentApp === 'KichboxesApp'}
      <KichboxesApp/>
    {:else if currentApp === 'WebpagesApp'}
      <WebpagesApp/>
    {:else if currentApp === 'ShopsApp'}
      <ShopsApp/>
    {:else if currentApp === 'OrdersApp'}
      <OrdersApp/>
    {:else if currentApp === 'EternalfriendApp'}
      <EternalfriendApp/>
    {/if}
  </main>
</div>
{#if showFooter}
  <footer>
    <div class="map">
      <a on:click|preventDefault={() => handle_menu_click('PagesApp', 'contact')} href="/contact">Contact</a>
      <a on:click|preventDefault={() => handle_menu_click('PagesApp', 'about')} href="/about">About</a>
      {#if tunedIn}
        <a on:click|preventDefault={handle_tuneup} href="/tuneup">Tune up a Guru?</a>
      {/if}
    </div>
    &copy; 2020 <a href='manitu.si'>Manitu</a> All Rights Reserved
  </footer>
{/if}